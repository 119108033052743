import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';
import { RouterService } from 'src/app/services/router.service';
import { TrademarkFamilyPopulated, TrademarkFamilyService } from 'src/app/services/trademark-family.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditTrademarkFamilyDialogComponent } from 'src/app/dialogs/edit-trademark-family-dialog/edit-trademark-family-dialog.component';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';
import { TrademarkService } from 'src/app/services/trademark.service';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { LinkTrademarkFamilyDialogComponent } from 'src/app/dialogs/link-trademark-family-dialog/link-trademark-family-dialog.component';

@Component({
  selector: 'app-trademark-family',
  templateUrl: './trademark-family.component.html',
  styleUrls: ['./trademark-family.component.scss']
})
export class TrademarkFamilyComponent {

  loading: boolean = false;
  family?: TrademarkFamilyPopulated;
  dataSource: MatTableDataSource<Trademark> = new MatTableDataSource<Trademark>([]);
  trademarks: Paginator<Trademark> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  mode: "CONSULTATION" | "EDITION" = "CONSULTATION";

  editTrademarkFamilyButton: {
    icon: string;
    class: string;
    action: Function
  } = {
      icon: 'edit',
      class: 'main',
      action: (selected: any) => {
        this.openEditDialog(selected)
      }
    }

  noTrademarkButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'TRADEMARK_FAMILY.LINK_TRADEMARKS',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.linkTrademarksDialog() }
    }

  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private trademarkFamilyService: TrademarkFamilyService,
    private translate: TranslateService
  ) {

  }



  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.retrieveTrademarkFamily(params['id']);
  }


  async retrieveTrademarkFamily(id: string) {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      this.family = await this.trademarkFamilyService.retrieve(id);

      this.trademarks.docs = this.family.trademarks.slice(0, this.trademarks.limit);
      this.trademarks.totalDocs = this.family.trademarks.length;
      this.trademarks.totalPages = Math.ceil(this.trademarks.totalDocs / this.trademarks.limit);
      this.dataSource = new MatTableDataSource<Trademark>(_.sortBy(this.trademarks.docs, this.trademarks.sort!));
      this.loading = false;
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async handlePage(event: PageEvent) {
    this.trademarks.limit = event.pageSize;
    this.trademarks.page = event.pageIndex + 1;
    this.trademarks.offset = event.pageIndex * this.trademarks.limit;
    if (this.family) {
      this.dataSource = new MatTableDataSource<Trademark>(this.family?.trademarks.slice(event.pageIndex * event.pageSize, (event.pageIndex + 1) * event.pageSize));
    }

  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      console.log(event)
      // this.trademarks.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      // this.retrieveTrademarks(false);
    }
  }

  async openEditDialog(trademark: Trademark) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        trademark: trademark,
        family: this.family
      }
    }
    const dialog = this.dialog.open(EditTrademarkFamilyDialogComponent, config);
    const result: { mode: "choose" | "new", newFamily?: Partial<TrademarkFamily>, family?: TrademarkFamily } = await firstValueFrom(dialog.afterClosed());
    try {
      if (result && result.mode === "choose" && result.family && result.family._id) {
        await this.trademarkService.updateFamily(trademark, result.family._id);
        this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.TRADEMARK_FAMILY_UPDATED', { name: trademark.name || trademark.identifierNumber }));
      } else if (result && result.mode === "new" && result.newFamily) {
        const toCreate = {
          ...result.newFamily,
          trademarks: [trademark._id]
        }
        const created = await this.trademarkFamilyService.create(toCreate);
        this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.FAMILY_CREATED', { name: created.label }));
        if (created && created._id) {
          await this.trademarkService.updateFamily(trademark, created._id);
          this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.TRADEMARK_FAMILY_UPDATED', { name: trademark.name || trademark.identifierNumber }));
        }
      }
      if (this.family && this.family._id) {
        this.retrieveTrademarkFamily(this.family._id)
      }

    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async update() {
    try {
      if (this.family) {
        this._message.emitChange("LOADING", "START");
        this.loading = true;
        const family = {
          ...this.family,
          trademarks: this.family.trademarks.map(t => t._id)
        }
        const updated = await this.trademarkFamilyService.update(family);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.FAMILY_UPDATED', { name: this.family.label }));
      }

      this.mode = 'CONSULTATION';
    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async delete() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'TRADEMARK_FAMILY.DELETE_FAMILY_TITLE',
        text: this.translate.instant('TRADEMARK_FAMILY.DELETE_FAMILY_TEXT', { name: this.family?.label ?? '', trademarks: this.family?.trademarks.length }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'TRADEMARK_FAMILY.DELETE_FAMILY_CONFIRMATION',
          value: this.family?.label
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.family && this.family._id) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.trademarkFamilyService.delete(this.family._id);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.router.navigateByUrl('/trademarks/families');
        this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.DELETE_FAMILY_SUCCESS', { name: this.family.label }));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async linkTrademarksDialog() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        family: this.family,
        trademarks: this.family ? this.family.trademarks : []
      }
    }
    const dialog = this.dialog.open(LinkTrademarkFamilyDialogComponent, config);
    const result: { linked: boolean, trademarks?: Trademark[] } = await firstValueFrom(dialog.afterClosed());
    if (result && result.linked && result.trademarks && result.trademarks.length > 0) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await Promise.all(result.trademarks.map(async (trademark) => {
          if (this.family && this.family._id) {
            await this.trademarkService.updateFamily(trademark, this.family._id);
          }
        }))
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        if (result.trademarks.length > 1) {
          this.toastr.success(this.translate.instant(`TRADEMARK_FAMILY.TRADEMARKS_LINKED_SUCCESS`, { count: result.trademarks.length }))
        } else {
          this.toastr.success(this.translate.instant(`TRADEMARK_FAMILY.TRADEMARK_LINKED_SUCCESS`))
        }
        if (this.family && this.family._id) {
          await this.retrieveTrademarkFamily(this.family?._id);
        }
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }
}
