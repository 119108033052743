<table mat-table [dataSource]="dataSource" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS_SHORT' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <div [class.clickable]="button !== undefined" (click)="button !== undefined ? toTrademark(trademark) : null">
        <span class="main bold" *ngIf="trademark.name">{{trademark.name}}</span>
        <span class="text light" *ngIf="!trademark.name && trademark.type !== 'Figurative'">-</span>
        <span class="small light" *ngIf="!trademark.name && trademark.type === 'Figurative'">({{'TRADEMARK.FIGURATIVE' |
          translate}})</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="identifier">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'TRADEMARK.IDENTIFIER_NUMBER_SHORT' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <div class="fx-column">
        <span class="text">{{trademark.identifierNumber}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="countryOfDesignation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'ENTITIES.TERRITORY' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <app-trademark-country [wipoCode]="trademark.countryOfDesignation"></app-trademark-country>
    </td>
  </ng-container>
  <ng-container matColumnDef="niceClassification">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'TRADEMARK.NICE_CLASSIFICATION_SHORT' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <div class="fx-row fx-wrap align-items-center justify-content-start">
        <app-trademark-nice-classification
          [niceClassification]="trademark.niceClassification"></app-trademark-nice-classification>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <span class="small light bold">
        {{'TRADEMARK.STATUS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <div class="fx-row align-items-center justify-content-start">
        <app-trademark-status [status]="trademark.status" *ngIf="trademark.status"></app-trademark-status>
      </div>
      <span class="text light" *ngIf="!trademark.status">-</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="representation">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'TRADEMARK.REPRODUCTION_SHORT' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <img [src]="trademark.reproductionURI" class="trademark-representation" *ngIf="trademark.reproductionURI" />
      <span class="text light" *ngIf="!trademark.reproductionURI">-</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <mat-icon [class]="button?.class || 'main'" fontSet="material-icons-outlined"
        [class.clickable]="button !== undefined" (click)="action(trademark)">{{button?.icon ||
        'keyboard_arrow_right'}}</mat-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let trademark; columns: displayedColumns;" [class.clickable]="button === undefined"
    (click)="button === undefined ? action(trademark) : null">
  </tr>
</table>
<div class="p-16" *ngIf="done && !loading && trademarks.totalDocs === 0">
  <span class="text light">{{'TRADEMARKS_LIST.SEARCH_NO_TRADEMARKS' | translate}}</span>
</div>
<mat-paginator [length]="trademarks.totalDocs || 0" [pageSize]="trademarks.limit || 0"
  [pageIndex]="trademarks && trademarks.page ? (trademarks.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
  (page)="handlePage($event)">
</mat-paginator>