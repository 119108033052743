import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NavComponent } from './pages/nav/nav.component';
import { HomeComponent } from './pages/home/home.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentComponent } from './pages/documents/document/document.component';
import { DocumentsListComponent } from './pages/documents/documents-list/documents-list.component';
import { CasesComponent } from './pages/cases/cases.component';
import { CasesListComponent } from './pages/cases/cases-list/cases-list.component';
import { CaseComponent } from './pages/cases/case/case.component';
import { TrademarksComponent } from './pages/trademarks/trademarks.component';
import { TrademarksListComponent } from './pages/trademarks/trademarks-list/trademarks-list.component';
import { TrademarkComponent } from './pages/trademarks/trademark/trademark.component';
import { TrademarkDeclareComponent } from './pages/trademarks/trademark-declare/trademark-declare.component';
import { CaseDeclareComponent } from './pages/cases/case-declare/case-declare.component';
import { AuthGuard } from './guards/auth.guard';
import { OrganizationComponent } from './pages/organization/organization/organization.component';
import { BatchesComponent } from './pages/documents/batches/batches.component';
import { BatchesListComponent } from './pages/documents/batches/batches-list/batches-list.component';
import { BatchComponent } from './pages/documents/batches/batch/batch.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UnauthenticatedComponent } from './pages/unauthenticated/unauthenticated.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { Role } from './models/user';
import { RoleGuard } from './guards/role.guard';
import { Error403Component } from './pages/errors/error403/error403.component';
import { Error404Component } from './pages/errors/error404/error404.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { IntegrationComponent } from './pages/integrations/integration/integration.component';
import { IntegrationsListComponent } from './pages/integrations/integrations-list/integrations-list.component';
import { SupportComponent } from './pages/support/support.component';
import { AdminComponent } from './pages/admin/admin.component';
import { OrganizationsComponent } from './pages/admin/organizations/organizations.component';
import { OrganizationDashboardComponent } from './pages/admin/organization-dashboard/organization-dashboard.component';
import { TrademarkFamiliesComponent } from './pages/trademarks/trademark-families/trademark-families.component';
import { TrademarkFamilyComponent } from './pages/trademarks/trademark-family/trademark-family.component';

export const routes: Routes = [
  {
    path: 'login',
    component: UnauthenticatedComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: UnauthenticatedComponent,
    children: [
      {
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  },
  {
    path: 'reset-password',
    component: UnauthenticatedComponent,
    children: [
      {
        path: '',
        component: ResetPasswordComponent
      }
    ]
  },
  {
    path: 'signup',
    component: UnauthenticatedComponent,
    children: [
      {
        path: ':nonce',
        component: SignupComponent
      }
    ]
  },
  {
    path: '',
    component: NavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cases',
        component: CasesComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER, Role.CASE_OPERATOR]
        },
        children: [
          {
            path: '',
            component: CasesListComponent
          },
          {
            path: 'new',
            component: CaseDeclareComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            },
          },
          {
            path: ':id',
            component: CaseComponent
          }
        ]
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'batches',
            component: BatchesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                component: BatchesListComponent
              },
              {
                path: ':id',
                component: BatchComponent
              }
            ]
          },
          {
            path: '',
            component: DocumentsListComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER, Role.COUNTRY_OPERATOR]
            }
          },
          {
            path: ':id',
            component: DocumentComponent
          }
        ]
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IntegrationsListComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            }
          },
          {
            path: ':type',
            component: IntegrationComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            }
          }
        ]
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trademarks',
        component: TrademarksComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: TrademarksListComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            }
          },
          {
            path: 'new',
            component: TrademarkDeclareComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            }
          },
          {
            path: 'families/:id',
            component: TrademarkFamilyComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER]
            }
          },
          {
            path: ':id',
            component: TrademarkComponent
          }
        ]
      },
      {
        path: 'organization',
        component: OrganizationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'errors/403',
        component: Error403Component,
        canActivate: [AuthGuard]
      },
      {
        path: 'errors/404',
        component: Error404Component,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: OrganizationsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN]
            }
          },
          {
            path: ':id',
            component: OrganizationDashboardComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.SUPERADMIN]
            }
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
