<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'DOCUMENTS_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'DOCUMENTS_LIST.SUBTITLE' | translate}}</span>
  </div>
  <div class="fx-row align-items-center justify-content-end">
    <div class="danger-button" *ngIf="selection && selection.selected && selection.selected.length > 0"
      (click)="deleteDocuments()">
      {{ 'ACTIONS.DELETE' | translate}} {{selection.selected.length}} {{((selection.selected.length > 1 ?
      'ENTITIES.DOCUMENTS' :
      'ENTITIES.DOCUMENT') | translate).toLowerCase()}}
    </div>
    <button class="button ml-10" (click)="toBatches()">
      {{'DOCUMENTS_LIST.UPLOADS_HISTORY' | translate}}
    </button>
    <button class="main-button ml-20" (click)="upload()" *ngIf="!loading && done && !noDocs" aclBasedElement
      resource="DOCUMENT" [scopes]="['C']">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
        {{'ACTIONS.UPLOAD' | translate}}
      </div>
    </button>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card *ngIf="!loading && done && !noDocs" class="p-0">
  <mat-drawer-container class="example-container">
    <mat-drawer mode="over" #drawer class="p-16" autoFocus="false">
      <div class="fx-column">
        <span class="text bold">{{'DOCUMENTS_LIST.FILTERS.LABEL' | translate}}</span>
        <span class="text">{{'DOCUMENTS_LIST.FILTERS.HINT' | translate}}</span>
        <app-document-class-select label="{{'ENTITIES.DOCUMENT_TYPES' | translate}}" [multiple]="true" [search]="true"
          [emitOnInit]="false" class="mt-20 fx-flex" (change)="onDocumentClassChange($event)"
          [(value)]="documentService.filters.documentClass" [noAutomatic]="true"></app-document-class-select>
        <app-trademark-family-select label="{{'ENTITIES.TRADEMARK_FAMILIES' | translate}}" [multiple]="true"
          [search]="true" class="mt-10 fx-flex" (change)="onTrademarkFamilyChange($event)"
          [(value)]="documentService.filters.trademarkFamily" [emitOnInit]="false"></app-trademark-family-select>
        <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" (change)="onTrademarkChange($event)" [(value)]="documentService.filters.trademark"
          [emitOnInit]="false"></app-trademark-select>
        <app-nice-select label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" [(value)]="documentService.filters.classNumber"
          (change)="onNiceClassificationChange($event)" [emitOnInit]="false"></app-nice-select>
        <app-territory-select label="{{'ENTITIES.TERRITORIES' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" [(value)]="documentService.filters.territories" (change)="onTerritoryChange($event)"
          [emitOnInit]="false"></app-territory-select>
        <app-period-select label="{{'ENTITIES.PERIOD' | translate}}" class="mt-10 fx-flex" [range]="true"
          (change)="onPeriodChange($event)" [(valueRange)]="documentService.filters.period"></app-period-select>
        <button class="mt-20" [class.main-button]="filtersCount() > 0" [class.button]="filtersCount() === 0"
          (click)="drawer.close()">
          {{(filtersCount() > 0 ? 'ACTIONS.SEE_RESULTS' : 'ACTIONS.CLOSE') | translate}}
        </button>
      </div>
    </mat-drawer>
    <mat-drawer-content class="p-16">
      <div class="mb-16" *ngIf="uploadingBox">
        <app-uploading-documents-box class="mb-16"></app-uploading-documents-box>
      </div>
      <div class="fx-row fx-wrap">
        <div class="fx-row fw-wrap align-items-center justify-content-between fx-flex-100"
          *ngIf="displayMode !== 'hierarchy'">
          <div class="fx-row fw-wrap align-items-center justify-content-start">
            <button class="button" (click)="drawer.toggle()">
              <div class="fx-row align-items-center justify-content-start">
                {{'DOCUMENTS_LIST.FILTERS.LABEL' | translate}}
                <div class="badge ml-5 white" *ngIf="filtersCount() > 0">
                  {{filtersCount()}}
                </div>
              </div>
            </button>
            <div class="ml-10 fx-row fx-wrap align-items-baseline justify-content-end">
              <app-animated-number [value]="count" [class]="'bold main'"></app-animated-number>
              <span class="text ml-5"><span class="bold main">{{((count > 1 ? 'ENTITIES.DOCUMENTS' :
                  'ENTITIES.DOCUMENT') |
                  translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
            </div>
          </div>
          <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search"
            [(value)]="documentService.filters.query" (change)="onQueryChange($event)" class="ml-10"></app-input>
        </div>
      </div>
      <div class="fx-row fw-wrap align-items-center justify-content-start  fx-flex-100"
        *ngIf="displayMode === 'hierarchy'">
        <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_1' | translate}}" [options]="level1Options"
          [multiple]="false" class="ml-10" [(value)]="levels[1]" (change)="onLevelChange(1,$event)" [emitOnInit]="false"
          style="width:180px;"></app-select>
        <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_2' | translate}}" [options]="level2Options"
          [multiple]="false" class="ml-10" [(value)]="levels[2]" (change)="onLevelChange(2,$event)" [emitOnInit]="false"
          style="width:180px;"></app-select>
        <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_3' | translate}}" [options]="level3Options"
          [multiple]="false" class="ml-10" [(value)]="levels[3]" (change)="onLevelChange(3,$event)" [emitOnInit]="false"
          style="width:180px;"></app-select>
      </div>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="!loading && done && !noDocs"
        (selectedTabChange)="onTabChange($event)" class="mt-20">
        <mat-tab>
          <ng-template mat-tab-label class="text">
            {{'DOCUMENTS_LIST.DISPLAY_MODE_LIST' | translate}}
          </ng-template>
          <ng-template matTabContent>
            <app-documents-table [filteredDocuments]="filteredDocuments" (sort)="onSortChange($event)"
              (page)="handlePage($event)" (reload)="reload()" [select]="true"
              [(selection)]="selection"></app-documents-table>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label class=" text">
            {{'DOCUMENTS_LIST.DISPLAY_MODE_REPORT' | translate}}
          </ng-template>
          <ng-template matTabContent>
            <app-documents-report #documentsReport [filters]="documentService.filters"></app-documents-report>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label class="text">
            {{'DOCUMENTS_LIST.DISPLAY_MODE_HIERARCHY' | translate}}
          </ng-template>
          <ng-template matTabContent>
            <app-documents-hierarchy [first]="levels[1]" [second]="levels[2]"
              [third]="levels[3]"></app-documents-hierarchy>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-card>
<app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
  [title]="'DOCUMENTS_LIST.NO_DOCUMENTS'" [message]="'DOCUMENTS_LIST.NO_DOCUMENTS_MESSAGE'"
  [button]="noDocumentButton"></app-empty-placeholder>