<table mat-table [dataSource]="dataSource" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'TRADEMARK_FAMILY.NAME' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let family">
      <span class="main bold">{{family.label}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'TRADEMARK_FAMILY.DESCRIPTION' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let family">
      <div class="fx-column">
        <span class="text">{{family.notes}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="trademarks">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'TRADEMARK_FAMILY.TRADEMARKS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let family">
      <span class="text"><b>{{family.trademarks}}</b> {{((family.trademarks > 1 ? 'ENTITIES.TRADEMARKS' :
        'ENTITIES.TRADEMARK') | translate).toLowerCase()}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let trademark">
      <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let family; columns: displayedColumns;" class="clickable"
    [routerLink]="'families/'+family._id">
  </tr>
</table>
<div class="p-16" *ngIf="done && !loading && families.totalDocs === 0">
  <span class="text light">{{'TRADEMARK_FAMILY.SEARCH_NO_FAMILIES' | translate}}</span>
</div>
<mat-paginator [length]="families.totalDocs || 0" [pageSize]="families.limit || 0"
  [pageIndex]="families && families.page ? (families.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
  (page)="handlePage($event)">
</mat-paginator>