import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DocumentAggregatedData, DocumentService } from 'src/app/services/document.service';
import { TrademarkFamilyPopulated } from 'src/app/services/trademark-family.service';

@Component({
  selector: 'app-trademark-family-coverage',
  templateUrl: './trademark-family-coverage.component.html',
  styleUrls: ['./trademark-family-coverage.component.scss']
})
export class TrademarkFamilyCoverageComponent {
  @Input() family?: TrademarkFamilyPopulated;

  count: number = 0;

  documentClasses: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTypes: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTerritories: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentPeriods: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTrademarks: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentNice: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };

  tradermarkSearch: { identifierNumber: string, countryOfDesignation: string }[] = []

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private toastr: ToastrService
  ) {

  }


  ngOnInit(): void {
    this.aggregateDocuments();
  }

  async aggregateDocuments() {
    await this.countDocuments();
    await this.aggregateDocumentsClasses();
    await this.aggregateDocumentsTrademarks();
    await this.aggregateDocumentsNice();
    await this.aggregateDocumentsTerritories();
    await this.aggregateDocumentsPeriods();
  }

  async countDocuments() {
    try {
      if (this.family && this.family.trademarks) {
        this.count = (await this.documentService.countAll(undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined)).count;
      }
    } catch (err) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  async aggregateDocumentsClasses() {
    try {
      if (this.family && this.family.trademarks) {
        this.documentClasses.started = true;
        this.documentClasses.loading = true;
        this.documentClasses.data = await this.documentService.aggregate('class', undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined);
        this.documentClasses.loading = false;
      }
    } catch (err) {
      this.documentClasses.loading = false;
      this.documentClasses.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTrademarks() {
    try {
      if (this.family && this.family.trademarks) {
        this.documentTrademarks.started = true;
        this.documentTrademarks.loading = true;
        this.documentTrademarks.data = await this.documentService.aggregate('trademark', undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined);
        this.documentTrademarks.loading = false;
      }
    } catch (err) {
      this.documentTrademarks.loading = false;
      this.documentTrademarks.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTerritories() {
    try {
      if (this.family && this.family.trademarks) {
        this.documentTerritories.started = true;
        this.documentTerritories.loading = true;
        this.documentTerritories.data = await this.documentService.aggregate('territory', undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined);
        this.documentTerritories.loading = false;
      }

    } catch (err) {
      this.documentTerritories.loading = false;
      this.documentTerritories.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsPeriods() {
    try {
      if (this.family && this.family.trademarks) {
        this.documentPeriods.started = true;
        this.documentPeriods.loading = true;
        this.documentPeriods.data = await this.documentService.aggregate('period', undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined);
        this.documentPeriods.loading = false;
      }

    } catch (err) {
      this.documentPeriods.loading = false;
      this.documentPeriods.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsNice() {
    try {
      if (this.family && this.family.trademarks) {
        this.documentNice.started = true;
        this.documentNice.loading = true;
        this.documentNice.data = await this.documentService.aggregate('nice', undefined, undefined, this.family.trademarks, undefined, undefined, undefined, undefined);
        this.documentNice.loading = false;
      }

    } catch (err) {
      this.documentNice.loading = false;
      this.documentNice.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  toTrademarkDocuments() {
    if (this.family && this.family.trademarks) {
      this.router.navigate(['/', 'documents'], {
        state: {
          trademarkFamilies: [{
            ...this.family,
            trademarks: this.family.trademarks.length
          }]
        }
      })
    }
  }
}
