import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Trademark, TrademarkType } from '../models/trademark';
import { TrademarkFamily } from '../models/trademarkFamily';
import { Paginator } from '../models/paginator';

export interface TrademarkFamilySuggestion {
  key: string;
  name?: string;
  type?: TrademarkType;
  trademarks: Partial<Trademark>[]
}


export type TrademarkFamilyCreate = Omit<TrademarkFamily, 'trademarks'> & {
  trademarks: (string | undefined)[]
};

export type TrademarkFamilyPopulated = Omit<TrademarkFamily, 'trademarks'> & {
  trademarks: Trademark[]
};

@Injectable({
  providedIn: 'root'
})
export class TrademarkFamilyService {


  constructor(
    private http: HttpClient
  ) {

  }

  retrieveAll(pageNumber: number, limit: number, offset: number, sort?: string, query?: string): Promise<Paginator<TrademarkFamily>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }


    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<TrademarkFamily>>(`${environment.backend}/trademarkFamilies/search`, { params }));
  }

  retrieve(id: string) {
    return firstValueFrom(this.http
      .get<TrademarkFamilyPopulated>(`${environment.backend}/trademarkFamilies/${id}`));
  }

  suggest() {
    return firstValueFrom(this.http
      .get<any>(`${environment.backend}/trademarkFamilies/suggest`));
  }

  create(family: TrademarkFamilyCreate) {
    return firstValueFrom(this.http
      .post<TrademarkFamily>(`${environment.backend}/trademarkFamilies`, family));
  }

  update(family: TrademarkFamilyCreate) {
    return firstValueFrom(this.http
      .put<TrademarkFamily>(`${environment.backend}/trademarkFamilies/${family._id}`, family));
  }

  delete(id: string) {
    return firstValueFrom(this.http
      .delete<any>(`${environment.backend}/trademarkFamilies/${id}`));
  }


}
