import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Case, CaseDocument } from 'src/app/models/case';
import { Document, DocumentFilters } from 'src/app/models/document';
import { Paginator } from 'src/app/models/paginator';
import { CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-non-use-attachment-dialog',
  templateUrl: './non-use-attachment-dialog.component.html',
  styleUrls: ['./non-use-attachment-dialog.component.scss']
})
export class NonUseAttachmentDialogComponent {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;

  public filters: DocumentFilters = {
    query: undefined,
    classNumber: [],
    territories: [],
    documentClass: [],
    trademark: [],
    trademarkFamily: [],
    period: {
      start: undefined,
      end: undefined
    }
  }

  documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 99,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: Document[] = [];

  selection = new SelectionModel<Document>(true, []);

  case?: Case;
  trademarkName?: string;

  document?: Document;
  documentOptions: { label: string, selected: boolean, value: any }[] = [];

  constructor(
    private _message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { case: Case },
    private caseService: CaseService,
    private dialogRef: MatDialogRef<NonUseAttachmentDialogComponent>,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }


  async ngOnInit() {
    this.case = this.data.case;
    this.trademarkName = this.case.trademark.name || this.case.trademark.identifierNumber;
    if (this.case) {
      this.filters.trademark = [{ ...this.case.trademark, _id: this.case.trademark.ref }];
      this.filters.nonUse = true;
    }

    this.loading = true;
    this.done = false;
    await this.retrieveDocuments(true);
    this.loading = false;
    this.done = true;
  }

  async retrieveDocuments(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");
      this.documentService.pager = { ...this.documentService.pager, ...await this.documentService.retrieveAll(this.documentService.pager.page, this.documentService.pager.limit, this.documentService.pager.offset, this.documentService.pager.sort, this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.nonUse, this.filters.query) };
      this.filteredDocuments = this.documentService.pager.docs;
      this.documentOptions = this.filteredDocuments.map((doc) => ({ value: doc, selected: false, label: doc.name }))

      if (init && this.documentService.pager.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.documentService.pager.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  formatTerritories(list: string[]) {
    const territories = list.map((datum) => this.translate.instant(`TERRITORIES.${datum.toUpperCase()}`))
    if (territories.length > 1) {
      return `${territories.splice(0, territories.length - 1).join(', ')} & ${territories[territories.length - 1]}`
    } else if (territories.length === 1) {
      return territories[0]
    } else {
      return '-'
    }
  }

  formatPeriod(document: Document) {
    moment.locale(this.translate.currentLang);
    return this.documentService.period(document);
  }

  onDocumentChange(event: any) {
    if (event && event.value) {
      this.document = event.value.value;
    }
  }

  canConfirm() {
    return this.document;
  }

  onCancel() {
    this.dialogRef.close({ linked: false, attachment: undefined });
  }

  onConfirm() {

    this.dialogRef.close({
      linked: true,
      attachment: this.document ? {
        document: this.document._id,
        name: this.document.name,
        description: this.translate.instant('CASE.NON_USE_PROOF_DESCRIPTION', {
          period: this.formatPeriod(this.document),
          territories: this.formatTerritories(this.document.territories)
        }),
        nonUse: true
      } : undefined
    });
  }
}
