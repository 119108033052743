import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';

@Component({
  selector: 'app-trademarks-table',
  templateUrl: './trademarks-table.component.html',
  styleUrls: ['./trademarks-table.component.scss']
})
export class TrademarksTableComponent implements OnInit {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  @Input() trademarks: Paginator<Trademark> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  @Input() displayedColumns: string[] = ['name', 'identifier', 'countryOfDesignation', 'niceClassification', 'status', 'representation', 'actions'];
  @Input() dataSource: MatTableDataSource<Trademark> = new MatTableDataSource<Trademark>([]);
  @Input() button?: {
    icon: string;
    class: string;
    action: Function
  };

  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<PageEvent>();


  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  async handlePage(event: PageEvent) {
    this.page.emit(event)
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.sort.emit(event)
    }
  }

  action(trademark: Trademark) {
    if (this.button && this.button.action) {
      this.button.action(trademark);
    } else {
      this.router.navigateByUrl(`/trademarks/${trademark._id}`)
    }
  }

  toTrademark(trademark: Trademark) {
    this.router.navigateByUrl(`/trademarks/${trademark._id}`)
  }


}
