import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Paginator } from 'src/app/models/paginator';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';

@Component({
  selector: 'app-trademark-families-table',
  templateUrl: './trademark-families-table.component.html',
  styleUrls: ['./trademark-families-table.component.scss']
})
export class TrademarkFamiliesTableComponent {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  @Input() families: Paginator<TrademarkFamily> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  @Input() displayedColumns: string[] = ['name', 'description', 'trademarks', 'actions'];
  @Input() dataSource: MatTableDataSource<TrademarkFamily> = new MatTableDataSource<TrademarkFamily>([]);

  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<PageEvent>();


  constructor() {

  }


  async handlePage(event: PageEvent) {
    this.page.emit(event)
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.sort.emit(event)
    }
  }
}
