<div class="fx-column">
  <app-back></app-back>
  <div class="fx-row align-items-center justify-content-between">
    <div class="fx-row align-items-start justify-content-start">
      <div class="fx-column" *ngIf="family && family.label">
        <span class="page-title">{{family.label}}</span>
        <span class="page-subtitle" *ngIf="family.notes">{{family.notes}}</span>
      </div>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button [cdkMenuTriggerFor]="menu" class="button ml-10" *ngIf="mode === 'CONSULTATION'">
        <div class="fx-row align-items-center justify-content-between fx-flex-100">
          <span class="dark fx-flex">{{'ACTIONS.ACTIONS' | translate}}</span>
          <mat-icon class="dark ml-5" fontSet="material-icons-outlined">arrow_drop_down</mat-icon>
        </div>
      </button>
      <ng-template #menu>
        <mat-card class="menu-container p-0 elevation" cdkMenu>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="linkTrademarksDialog()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">link</mat-icon>
              <span class="text">{{'TRADEMARK_FAMILY.LINK_TRADEMARKS' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="mode = 'EDITION'" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">edit</mat-icon>
              <span class="text">{{'ACTIONS.EDIT' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="delete()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['D']">
              <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
              <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
            </div>
          </div>
        </mat-card>
      </ng-template>
      <button class="button ml-20 fx-flex" *ngIf="mode === 'EDITION'" (click)="mode = 'CONSULTATION'">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" *ngIf="mode === 'EDITION'" (click)="update()" [disabled]="loading"
        [class.disabled]="loading">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card class="p-0 mb-16" *ngIf="family">
  <div class="p-16">
    <span class="text bold">{{'TRADEMARK_FAMILY.INFORMATION' | translate}}</span>
  </div>
  <mat-divider></mat-divider>
  <div class="p-8 fx-row fx-wrap align-items-start justify-content-start" *ngIf="mode === 'CONSULTATION'">
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'TRADEMARK_FAMILY.LABEL' | translate}}</span>
      <span class="text">{{family.label}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'TRADEMARK_FAMILY.NOTES' | translate}}</span>
      <span class="text">{{family.notes}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'TRADEMARK_FAMILY.TYPE' | translate}}</span>
      <span class="text" *ngIf="family.type">{{'TRADEMARK.TYPES.'+family.type.toUpperCase() | translate}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'TRADEMARK_FAMILY.NAME' | translate}}</span>
      <span class="text">{{family.name || 'N/A'}}</span>
    </div>
  </div>
  <div class="p-8 fx-row fx-wrap align-items-start justify-content-start" *ngIf="mode === 'EDITION'">
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-input label="{{'TRADEMARK_FAMILY.LABEL' | translate}}" placeholder="{{'TRADEMARK_FAMILY.LABEL' | translate}}"
        type="text" [(value)]="family.label"></app-input>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-input label="{{'TRADEMARK_FAMILY.NOTES' | translate}}" placeholder="{{'TRADEMARK_FAMILY.NOTES' | translate}}"
        type="text" [(value)]="family.notes"></app-input>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-trademark-type-select label="{{'TRADEMARK_FAMILY.TYPE' | translate}}" [multiple]="false" [search]="true"
        class="fx-flex-100" [(value)]="family.type" [emitOnInit]="false" [labelOutside]="true"
        [disabled]="true"></app-trademark-type-select>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-input label="{{'TRADEMARK_FAMILY.NAME' | translate}}" placeholder="{{'TRADEMARK_FAMILY.NAME' | translate}}"
        type="text" [(value)]="family.name" [disabled]="true"></app-input>
    </div>
  </div>
</mat-card>
<mat-card class="p-0" *ngIf="family && family.trademarks && family.trademarks.length > 0">
  <div class="p-16 fx-row align-items-center justify-content-between">
    <span class="text bold">{{'TRADEMARK_FAMILY.TRADEMARKS' | translate}}</span>
    <div class="fx-row align-items-center justify-content-end">
      <app-animated-number [value]="trademarks.totalDocs" [class]="'bold main'"></app-animated-number>
      &nbsp;
      <span class="text"><span class="bold main">{{((trademarks.totalDocs > 1 ? 'ENTITIES.TRADEMARKS' :
          'ENTITIES.TRADEMARK') |
          translate).toLowerCase()}}</span> {{('TRADEMARK_FAMILY.IN_FAMILY' | translate).toLowerCase()}}</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-trademarks-table [dataSource]="dataSource" [trademarks]="trademarks" (sort)="onSortChange($event)"
    (page)="handlePage($event)" [button]="editTrademarkFamilyButton"></app-trademarks-table>
</mat-card>
<app-trademark-family-coverage [family]="family"
  *ngIf="family && family.trademarks && family.trademarks.length > 0"></app-trademark-family-coverage>
<app-empty-placeholder *ngIf="family && (!family.trademarks || family.trademarks.length == 0) && !loading"
  [title]="'TRADEMARK_FAMILY.NO_TRADEMARKS'" [offset]="340" [message]="'TRADEMARK_FAMILY.NO_TRADEMARKS_MESSAGE'"
  [button]="noTrademarkButton"></app-empty-placeholder>