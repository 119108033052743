import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Case } from 'src/app/models/case';
import { DocumentFilters } from 'src/app/models/document';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { CaseService } from 'src/app/services/case.service';
import { DocumentAggregatedData, DocumentService } from 'src/app/services/document.service';
import * as am4core from "@amcharts/amcharts4/core";

@Component({
  selector: 'app-case-coverage',
  templateUrl: './case-coverage.component.html',
  styleUrls: ['./case-coverage.component.scss']
})
export class CaseCoverageComponent implements OnInit, OnChanges {
  @Input() case?: Case;
  @Input() propertyOffice?: PropertyOffice;
  @Output() toProofs = new EventEmitter<any>();

  documentClasses: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTypes: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTerritories: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentPeriods: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentNice: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };

  filters: DocumentFilters = {
    classNumber: [],
    trademark: [],
    trademarkFamily: [],
    territories: [],
    period: {
      start: undefined,
      end: undefined
    },
    documentClass: []
  }

  init: boolean = false;

  constructor(
    private caseService: CaseService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    // am4core.options.queue = true;
  }


  ngOnInit(): void {

    if (this.case && this.propertyOffice) {
      this.aggregateDocuments();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.init && this.case && this.propertyOffice) {
      this.aggregateDocuments();
    }
  }

  async aggregateDocuments() {
    this.init = true;
    await this.aggregateDocumentsClasses();
    await this.aggregateDocumentsNice();
    await this.aggregateDocumentsTerritories();
    await this.aggregateDocumentsPeriods();
  }


  async aggregateDocumentsClasses() {
    try {
      if (this.case && this.case._id) {
        this.documentClasses.started = true;
        this.documentClasses.loading = true;
        this.documentClasses.data = await this.caseService.aggregateDocuments(this.case._id, 'class');
        this.documentClasses.loading = false;
      }
    } catch (err) {
      this.documentClasses.loading = false;
      this.documentClasses.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  async aggregateDocumentsTerritories() {
    try {
      if (this.case && this.case._id) {
        this.documentTerritories.started = true;
        this.documentTerritories.loading = true;
        this.documentTerritories.data = await this.caseService.aggregateDocuments(this.case._id, 'territory');
        this.documentTerritories.loading = false;
      }

    } catch (err) {
      this.documentTerritories.loading = false;
      this.documentTerritories.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsPeriods() {
    try {
      if (this.case && this.case._id) {
        this.documentPeriods.started = true;
        this.documentPeriods.loading = true;
        this.documentPeriods.data = await this.caseService.aggregateDocuments(this.case._id, 'period');
        this.documentPeriods.loading = false;
      }

    } catch (err) {
      this.documentPeriods.loading = false;
      this.documentPeriods.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsNice() {
    try {
      if (this.case && this.case._id) {
        this.documentNice.started = true;
        this.documentNice.loading = true;
        this.documentNice.data = await this.caseService.aggregateDocuments(this.case._id, 'nice');
        this.documentNice.loading = false;
      }

    } catch (err) {
      this.documentNice.loading = false;
      this.documentNice.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

}
