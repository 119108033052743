import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Trademark } from 'src/app/models/trademark';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';

@Component({
  selector: 'app-link-trademark-family-dialog',
  templateUrl: './link-trademark-family-dialog.component.html',
  styleUrls: ['./link-trademark-family-dialog.component.scss']
})
export class LinkTrademarkFamilyDialogComponent {
  family?: TrademarkFamily;
  trademarks?: Trademark[];
  disabledValues: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { family: TrademarkFamily, trademarks: Trademark[] },
    private dialogRef: MatDialogRef<LinkTrademarkFamilyDialogComponent>,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.family = this.data.family;
    this.disabledValues = this.data.trademarks.map((tm) => tm._id!);
  }

  isDisabled() {
    return !this.trademarks || this.trademarks.length == 0;
  }


  onLink() {
    this.dialogRef.close({
      linked: true,
      trademarks: this.trademarks
    })
  }


  onCancel() {
    this.dialogRef.close({
      linked: false
    })
  }
}
