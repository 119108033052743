<div class="p-20 fx-row fx-wrap align-items-center justify-content-start ">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'TRADEMARK_FAMILY.CREATE_FAMILY' | translate}}</span>
  </div>
  <span class="text mt-20">{{'TRADEMARK_FAMILY.CREATE_FAMILY_HINT' | translate}}</span>
  <span class="text" *ngIf="trademarks.length > 0">{{'TRADEMARK_FAMILY.CREATE_FAMILY_TRADEMARKS' | translate}}
    <b>{{trademarks.length}}
      {{((trademarks.length > 1 ? 'ENTITIES.TRADEMARKS' : 'ENTITIES.TRADEMARK') | translate).toLowerCase()}}</b>.</span>
  <div class="fx-flex-100 fx-row align-items-center justify-content-center mt-20">
    <div class="fx-flex-50 pr-10">
      <app-input [placeholder]="name || ('TRADEMARK_FAMILY.NAME' | translate)" type="text" [(value)]="name"
        class="fx-flex-100" [disabled]="name !== undefined || type === 'Figurative'"></app-input>
    </div>
    <div class="fx-flex-50 pl-10">
      <app-trademark-type-select label="{{'TRADEMARK_FAMILY.TYPE' | translate}}" [multiple]="false" [search]="true"
        class="fx-flex-100" (change)="onTrademarkTypeChange($event)" [(value)]="type"
        [emitOnInit]="false"></app-trademark-type-select>
    </div>
  </div>
  <div class="fx-flex-100 mt-10">
    <app-input [placeholder]="label || ('TRADEMARK_FAMILY.LABEL' | translate)" type="text" [(value)]="label"
      class="fx-flex-100"></app-input>
  </div>
  <div class="fx-flex-100 mt-10">
    <app-input [placeholder]="hint()" type="text" [(value)]="notes" class="fx-flex-100"></app-input>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onCreate()" [disabled]="isDisabled()">{{'ACTIONS.CREATE'
      | translate}}</button>
  </div>
</div>