<div class="p-20 fx-row fx-wrap align-items-center justify-content-start ">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'TRADEMARK_FAMILY.EDIT_FAMILY' | translate}}</span>
  </div>
  <span class="text mv-20">{{'TRADEMARK_FAMILY.EDIT_FAMILY_HINT' | translate}} <b>{{trademark?.name ||
      trademark?.identifierNumber}} ({{trademark?.countryOfDesignation}})</b>.</span>
  <span class="label fx-flex-100">{{'TRADEMARK_FAMILY.EDIT_FAMILY_MODE' | translate}}</span>
  <mat-radio-group [(ngModel)]="mode" class="fx-flex-100" color="primary">
    <mat-radio-button value="choose">{{'TRADEMARK_FAMILY.EDIT_FAMILY_MODE_CHOOSE' | translate}}</mat-radio-button>
    <mat-radio-button value="new">{{'TRADEMARK_FAMILY.EDIT_FAMILY_MODE_NEW' | translate}}</mat-radio-button>
  </mat-radio-group>
  <app-trademark-family-select label="{{'ENTITIES.TRADEMARK_FAMILY' | translate}}" [multiple]="false" [search]="true"
    class="fx-flex-100 mt-20" (change)="onTrademarkFamilyChange($event)" [(value)]="family" [emitOnInit]="true"
    *ngIf="mode === 'choose'"></app-trademark-family-select>
  <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-start" *ngIf="mode === 'new' && family">
    <div class="fx-flex-100 fx-row align-items-center justify-content-center mt-20">
      <div class="fx-flex-50 pr-10">
        <app-input [placeholder]="family.name" type="text" [(value)]="newFamily.name" class="fx-flex-100"
          [disabled]="true"></app-input>
      </div>
      <div class="fx-flex-50 pl-10">
        <app-trademark-type-select label="{{'TRADEMARK_FAMILY.TYPE' | translate}}" [multiple]="false" [search]="true"
          class="fx-flex-100" (change)="onTrademarkTypeChange($event)" [(value)]="family.type" [emitOnInit]="false"
          [disabled]="true"></app-trademark-type-select>
      </div>
    </div>
    <div class="fx-flex-100 mt-16">
      <app-input [placeholder]="'TRADEMARK_FAMILY.LABEL' | translate" type="text" [(value)]="newFamily.label"
        class="fx-flex-100"></app-input>
    </div>
    <div class="fx-flex-100 mt-16">
      <app-input [placeholder]="'TRADEMARK_FAMILY.NOTES' | translate" type="text" [(value)]="newFamily.notes"
        class="fx-flex-100"></app-input>
    </div>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="mode === 'choose' ? onChoose() : onCreate()"
      [disabled]="isDisabled()">{{(mode === 'choose' ? 'ACTIONS.UPDATE' : 'ACTIONS.CREATE')
      | translate}}</button>
  </div>
</div>