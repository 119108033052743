import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Trademark, TrademarkType } from 'src/app/models/trademark';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';

@Component({
  selector: 'app-edit-trademark-family-dialog',
  templateUrl: './edit-trademark-family-dialog.component.html',
  styleUrls: ['./edit-trademark-family-dialog.component.scss']
})
export class EditTrademarkFamilyDialogComponent {
  mode: "choose" | "new" = "choose";
  newFamily: Partial<TrademarkFamily> = {
    label: undefined,
    name: undefined,
    notes: undefined,
    type: undefined
  };
  family?: TrademarkFamily;
  trademark?: Trademark;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { trademark: Trademark, family: TrademarkFamily },
    private dialogRef: MatDialogRef<EditTrademarkFamilyDialogComponent>,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.trademark = this.data.trademark;
    this.family = this.data.family;
    this.newFamily.name = this.trademark.name;
    if (this.trademark && this.trademark.type)
      this.newFamily.type = this.trademark.type as TrademarkType;
  }

  onTrademarkFamilyChange(event: any) {
    this.family = event.value;
  }

  isDisabled() {
    return this.mode === "new" && (!this.newFamily.label || !this.newFamily.name || !this.newFamily.type);
  }

  onTrademarkTypeChange(event: any) {
    console.log(event)
  }


  onCreate() {
    this.dialogRef.close({
      mode: this.mode,
      newFamily: this.newFamily
    })
  }

  onChoose() {
    this.dialogRef.close({
      mode: this.mode,
      family: this.family
    })
  }

  onCancel() {
    this.dialogRef.close({
    })
  }
}
