<div class="fx-row fx-wrap align-items-stretch justify-content-start mt-20">
  <div class="border-box" [class]="file ? 'fx-flex-30 pr-10' :'fx-flex-100'">
    <mat-card class="fx-flex-100 h-100">
      <div class="fx-row fx-wrap align-items-center justify-content-center  h-100" style="position:relative;">
        <span class="text fx-flex-100" *ngIf="!file">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARKS_INSTRUCTIONS' |
          translate}}</span>
        <div class="mt-20 fx-flex-100" *ngIf='!file'>
          <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv">
          <div class="file-upload fx-column justify-content-center align-items-center" (click)="fileUpload.click()"
            [class.active]="active">
            <div class="upload-icon-container">
              <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
            </div>
            <div class="mt-10 fx-column">
              <span class="text main text-center">{{"TRADEMARK_DECLARE.IMPORT_INSTRUCTION" | translate}}</span>
              <span class="small main text-center">{{"TRADEMARK_DECLARE.IMPORT_FILE_TYPES" | translate}}</span>
            </div>
          </div>
        </div>
        <div class="fx-column align-items-center justify-content-center fx-flex-100" *ngIf='file'>
          <img [src]="icon(file)" [height]="50" class="mb-10" />
          <div class="fx-column align-items-center justify-content-center">
            <span class="text bold text-center">{{file.name}}</span>
            <span class="text light text-center">{{size(file)}}</span>
          </div>
        </div>
        <button mat-icon-button class="remove-file-button" (click)="removeFile()" *ngIf="file"
          [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'TRADEMARK_DECLARE.IMPORT_REMOVE_FILE' | translate"
          matTooltipClass="uzai-tooltip">
          <mat-icon fontSet="material-icons-outlined">remove_circle_outline</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
  <div class="fx-flex-70 pl-10  border-box" *ngIf="file && import.prepared">
    <mat-card class="fx-flex-100 h-100">
      <div class="fx-row fx-wrap">
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="import.isExcel">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_SHEET' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_SHEET_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-select label="{{'TRADEMARK_DECLARE.IMPORT_SHEET' | translate}}" [options]="import.sheets"
              [multiple]="false" class="fx-flex-100" [(value)]="import.sheet"
              *ngIf="import.sheets.length > 0"></app-select>
          </div>
        </div>
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="!import.isExcel">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_DELIMITER' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_DELIMITER_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-input [placeholder]="'TRADEMARK_DECLARE.IMPORT_DELIMITER' | translate" type="text" class="fx-flex-100"
              [(value)]="import.delimiter"></app-input>
          </div>
        </div>
        <mat-divider class="mv-16 fx-flex-100"></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_FIRST_LINE' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_FIRST_LINE_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-input [placeholder]="'TRADEMARK_DECLARE.IMPORT_FIRST_LINE' | translate" type="number"
              class="fx-flex-100" [(value)]="import.firstLine"></app-input>
          </div>
        </div>
        <mat-divider class="mv-16 fx-flex-100"></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_IDENTIFIER_COLUMN' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_IDENTIFIER_COLUMN_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-input [placeholder]="'TRADEMARK_DECLARE.IMPORT_IDENTIFIER_COLUMN' | translate" type="text"
              class="fx-flex-100" [(value)]="import.identifierColumn"></app-input>
          </div>
        </div>
        <mat-divider class="mv-16 fx-flex-100"></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_COUNTRY_COLUMN' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_COUNTRY_COLUMN_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-input [placeholder]="'TRADEMARK_DECLARE.IMPORT_COUNTRY_COLUMN' | translate" type="text"
              class="fx-flex-100" [(value)]="import.countryColumn"></app-input>
          </div>
        </div>
        <!-- <mat-divider class="mv-16 fx-flex-100"></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
          <div class="fx-flex-70 fx-column align-items-start justify-content-start">
            <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARK_FAMILY' | translate}}</span>
            <span class="text light">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARK_FAMILY_HINT' | translate}}</span>
          </div>
          <div class="fx-flex-30 fx-row align-items-start justify-content-start">
            <app-input [placeholder]="'TRADEMARK_DECLARE.IMPORT_COUNTRY_COLUMN' | translate" type="text" class="fx-flex-100"
              [(value)]="import.countryColumn"></app-input>
          </div>
        </div> -->
        <mat-divider class="mv-16 fx-flex-100"></mat-divider>
        <div class="fx-row align-items-center justify-content-end fx-flex-100">
          <button class="button" (click)="_parseFile(file)" [disabled]="parseFileDisabled()"
            [class.disabled]="parseFileDisabled()">
            {{'ACTIONS.VALIDATE' | translate}}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <app-progress-bar mode="indeterminate" [loading]="loading" class="mv-16 fx-flex-100"></app-progress-bar>
  <mat-card class="p-0 fx-flex-100 mb-16" *ngIf="file && import.prepared && import.parsed && done && !loading">
    <div class="p-16 fx-column">
      <span class="text bold">{{'TRADEMARK_DECLARE.IMPORT_RESULTS' | translate}}</span>
      <span class="text">{{'TRADEMARK_DECLARE.IMPORT_RESULTS_HINT' | translate}}</span>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="hasErrors()" class="ph-16 pt-16 pb-8">
      <div class="fx-row align-items-center justify-content-between mb-8"
        *ngIf="import.errors.missingIdentifier.length > 0">
        <div class="fx-row align-items-center justify-content-start">
          <mat-icon class="red mr-5" fontSet="material-icons-outlined">cancel</mat-icon>
          <span class="text">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARKS_MISSING_IDENTIFIER' | translate}}</span>
        </div>
        <a (click)="overview('missingIdentifier','TRADEMARK_DECLARE.IMPORT_TRADEMARKS_MISSING_IDENTIFIER')">{{import.errors.missingIdentifier.length}}
          {{('TRADEMARK_DECLARE.TRADEMARKS' | translate).toLowerCase()}}</a>
      </div>
      <div class="fx-row align-items-center justify-content-between mb-8"
        *ngIf="import.errors.missingCountry.length > 0">
        <div class="fx-row align-items-center justify-content-start">
          <mat-icon class="red mr-5" fontSet="material-icons-outlined">cancel</mat-icon>
          <span class="text">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARKS_MISSING_COUNTRY' | translate}}</span>
        </div>
        <a (click)="overview('missingCountry','TRADEMARK_DECLARE.IMPORT_TRADEMARKS_MISSING_COUNTRY')">{{import.errors.missingCountry.length}}
          {{('TRADEMARK_DECLARE.TRADEMARKS' | translate).toLowerCase()}}</a>
      </div>
      <div class="fx-row align-items-center justify-content-between mb-8"
        *ngIf="import.errors.invalidCountry.length > 0">
        <div class="fx-row align-items-center justify-content-start">
          <mat-icon class="red mr-5" fontSet="material-icons-outlined">cancel</mat-icon>
          <span class="text">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARKS_INVALID_COUNTRY' | translate}}</span>
        </div>
        <a (click)="overview('invalidCountry','TRADEMARK_DECLARE.IMPORT_TRADEMARKS_INVALID_COUNTRY')">{{import.errors.invalidCountry.length}}
          {{('TRADEMARK_DECLARE.TRADEMARKS' | translate).toLowerCase()}}</a>
      </div>
      <div class="fx-row align-items-center justify-content-between mb-8"
        *ngIf="import.errors.unprocessable.length > 0">
        <div class="fx-row align-items-center justify-content-start">
          <mat-icon class="red mr-5" fontSet="material-icons-outlined">cancel</mat-icon>
          <span class="text">{{'TRADEMARK_DECLARE.IMPORT_TRADEMARKS_UNPROCESSABLE' | translate}}</span>
        </div>
        <a (click)="overview('unprocessable','TRADEMARK_DECLARE.IMPORT_TRADEMARKS_UNPROCESSABLE' )">{{import.errors.unprocessable.length}}
          {{('TRADEMARK_DECLARE.TRADEMARKS' | translate).toLowerCase()}}</a>
      </div>
    </div>
    <mat-divider *ngIf="hasErrors()"></mat-divider>
    <table mat-table [dataSource]="dataSource" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary" [checked]="isAllSelected()"
            [indeterminate]="isSomeSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(row) : null"
            [checked]="isSelected(row)" *ngIf="!isDisabled(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="tmName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class=" small light bold">
            {{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS_SHORT' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <div class="fx-column">
            <span class="main bold" *ngIf="trademark.tmName">{{trademark.tmName}}</span>
            <span class="text light" *ngIf="!trademark.tmName && trademark.tradeMarkType !== 'Figurative'">-</span>
            <span class="small light"
              *ngIf="!trademark.tmName && trademark.tradeMarkType === 'Figurative'">({{'TRADEMARK.FIGURATIVE'
              |
              translate}})</span>
            <span class="small light" *ngIf="trademark.isDesignation">{{'TRADEMARK.DESIGNATED_COUNTRY'
              | translate}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="applicationNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class=" small light bold">
            {{'TRADEMARK.APPLICATION_NUMBER_SHORT' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <div class="fx-column">
            <span class="text">{{trademark.applicationNumber}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="tmOffice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="small light bold">
            {{'ENTITIES.TERRITORY' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <app-trademark-country [wipoCode]="trademark.tmOffice"></app-trademark-country>
        </td>
      </ng-container>
      <ng-container matColumnDef="niceClassification">
        <th mat-header-cell *matHeaderCellDef>
          <span class=" small light bold">
            {{'TRADEMARK.NICE_CLASSIFICATION_SHORT' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <div class="fx-row fx-wrap align-items-center justify-content-start">
            <app-trademark-nice-classification
              [niceClassification]="trademark.niceClass"></app-trademark-nice-classification>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <span class="small light bold">
            {{'TRADEMARK.STATUS' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <div class="fx-row align-items-center justify-content-start">
            <app-trademark-status [status]="trademark.tradeMarkStatus"
              *ngIf="trademark.tradeMarkStatus"></app-trademark-status>
            <span class="text light" *ngIf="!trademark.tradeMarkStatus">-</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="representation">
        <th mat-header-cell *matHeaderCellDef>
          <span class=" small light bold">
            {{'TRADEMARK.REPRODUCTION_SHORT' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let trademark">
          <img [src]="trademark.markImageURI" class="trademark-representation" *ngIf="trademark.markImageURI" />
          <span class="text light" *ngIf="!trademark.markImageURI">-</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let trademark; columns: displayedColumns;">
      </tr>
    </table>
  </mat-card>
</div>
<!-- -->