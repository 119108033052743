<div class="fx-column" *ngIf="trademark && mode === 'CONSULTATION'">
  <div class="fx-row fx-wrap align-items-stretch justify-content-start fx-flex-100">
    <div class="fx-flex-70 pr-8 pb-8 border-box">
      <mat-card class="p-0">
        <div class="p-16">
          <span class="text bold">{{'TRADEMARK.INFORMATION' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <div
          class="fx-flex-100 ph-16 pv-8 border-box main-bg fx-row align-items-center justify-content-between clickable">
          <div class="fx-column">
            <span class="small white">{{'ENTITIES.TRADEMARK_FAMILY' | translate}}</span>
            <span class="text white bold" *ngIf="getFamily().exist">{{getFamily().label}}</span>
            <span class="text white bold" *ngIf="!getFamily().exist">{{'TRADEMARK.NO_FAMILY' | translate}}</span>
          </div>
          <button [cdkMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon class="white" fontSet="material-icons-outlined">more_vert</mat-icon>
          </button>
          <ng-template #menu>
            <mat-card class="menu-container p-0 elevation" cdkMenu>
              <div class="fx-column">
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="toFamily()" cdkMenuItem *ngIf="getFamily().exist">
                  <span class="text">{{'TRADEMARK.SEE_FAMILY' | translate}}</span>
                </div>
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="actionFamily()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']">
                  <span class="text">{{(getFamily().exist ? 'TRADEMARK.UPDATE_FAMILY' : 'TRADEMARK.LINK_FAMILY') |
                    translate}}</span>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </div>
        <div class="p-8 fx-row fx-wrap align-items-start justify-content-start">
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS' | translate}}</span>
            <span class="text" *ngIf="trademark.name">{{trademark.name}}</span>
            <span class="text" *ngIf="!trademark.name && trademark.type !== 'Figurative'">-</span>
            <span class="text" *ngIf="!trademark.name && trademark.type === 'Figurative'">({{'TRADEMARK.FIGURATIVE' |
              translate}})</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.APPLICATION_NUMBER' | translate}}</span>
            <span class="text">{{trademark.applicationNumber || '-'}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.REGISTRATION_NUMBER' | translate}}</span>
            <span class="text">{{trademark.registrationNumber || '-'}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.DESIGNATION_COUNTRY' | translate}}</span>
            <span class="text">{{getCountry()}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.KIND' | translate}}</span>
            <span class="text">{{trademark.kind|| '-'}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.TYPE' | translate}}</span>
            <span class="text">{{trademark.type || '-'}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.APPLICATION_DATE' | translate}}</span>
            <span class="text">{{formatDate(trademark.applicationDate)}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.PUBLICATION_DATE' | translate}}</span>
            <span class="text">{{formatDate(trademark.publicationDate)}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.REGISTRATION_DATE' | translate}}</span>
            <span class="text">{{formatDate(trademark.registrationDate)}}</span>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <span class="small light">{{'TRADEMARK.EXPIRATION_DATE' | translate}}</span>
            <span class="text">{{formatDate(trademark.expirationDate)}}</span>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="fx-flex-30 pl-8 pb-8 border-box">
      <div class="fx-column h-100">
        <mat-card class="p-0 mb-8 fx-flex">
          <div class="fx-column h-100">
            <div class="p-16">
              <span class="text bold">{{'TRADEMARK.REPRODUCTION_SHORT' | translate}}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="fx-flex office" *ngIf="trademark.reproductionURI"
              [style.background-image]="'url('+trademark.reproductionURI+')'">
            </div>
            <div class="p-16 fx-column align-items-center justify-content-center fx-flex border-box"
              *ngIf="!trademark.reproductionURI">
              <span class="small light">{{'TRADEMARK.NO_REPRODUCTION' |
                translate}}</span>
            </div>
          </div>
        </mat-card>
        <mat-card class="p-0 mt-8 fx-flex">
          <div class="fx-column h-100">
            <div class="p-16">
              <span class="text bold">{{'TRADEMARK.OFFICE' | translate}}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="fx-flex office"
              matTooltip="{{trademark.propertyOffice.name}} ({{trademark.propertyOffice.code}})"
              *ngIf="trademark.propertyOffice && trademark.propertyOffice.logo"
              [style.background-image]="'url('+trademark.propertyOffice.logo+')'">
            </div>
            <div class="fx-column align-items-center justify-content-center fx-flex border-box p-16"
              *ngIf="!trademark.propertyOffice">
              <span class="small light">{{'TRADEMARK.NO_OFFICE' |
                translate}}</span>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="fx-flex-100 pb-8 pt-8 border-box">
    <mat-card class="p-0">
      <div class="p-16">
        <span class="text bold">{{'TRADEMARK.CLASSIFICATION' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <div class="fx-column align-items-start justify-content-start mb-10"
          *ngFor="let niceClass of trademark.niceClassification">
          <div class="chip main fx-row align-items-center justify-content-start"
            [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'NICE_CLASSES.'+niceClass.classNumber | translate">
            <b>{{'NICE_CLASSES.CLASS_NUMBER' |
              translate}}{{niceClass.classNumber}}</b>
          </div>
          <span class="text">{{niceClass.details}}</span>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div class="fx-column" *ngIf="trademark && mode === 'EDITION'">
  <div class="fx-row fx-wrap align-items-stretch justify-content-start fx-flex-100">
    <div class="fx-flex-70 pr-8 pb-8 border-box">
      <mat-card class="p-0">
        <div class="p-16">
          <span class="text bold">{{'TRADEMARK.INFORMATION' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <div
          class="fx-flex-100 ph-16 pv-8 border-box main-bg fx-row align-items-center justify-content-between clickable">
          <div class="fx-column">
            <span class="small white">{{'ENTITIES.TRADEMARK_FAMILY' | translate}}</span>
            <span class="text white bold" *ngIf="getFamily().exist">{{getFamily().label}}</span>
            <span class="text white bold" *ngIf="!getFamily().exist">{{'TRADEMARK.NO_FAMILY' | translate}}</span>
          </div>
          <button [cdkMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon class="white" fontSet="material-icons-outlined">more_vert</mat-icon>
          </button>
          <ng-template #menu>
            <mat-card class="menu-container p-0 elevation" cdkMenu>
              <div class="fx-column">
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="toFamily()" cdkMenuItem *ngIf="getFamily().exist">
                  <span class="text">{{'TRADEMARK.SEE_FAMILY' | translate}}</span>
                </div>
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="actionFamily()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']">
                  <span class="text">{{(getFamily().exist ? 'TRADEMARK.UPDATE_FAMILY' : 'TRADEMARK.LINK_FAMILY') |
                    translate}}</span>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </div>
        <div class="p-8 fx-row fx-wrap align-items-start justify-content-start">
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-input label="{{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS' | translate}}"
              placeholder="{{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS' | translate}}" type="text"
              [(value)]="trademark.name"></app-input>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-input label="{{'TRADEMARK.APPLICATION_NUMBER' | translate}}"
              placeholder="{{'TRADEMARK.APPLICATION_NUMBER' | translate}}" type="text"
              [(value)]="trademark.applicationNumber" [error]="validate('applicationNumber')"></app-input>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-input label="{{'TRADEMARK.REGISTRATION_NUMBER' | translate}}"
              placeholder="{{'TRADEMARK.REGISTRATION_NUMBER' | translate}}" type="text"
              [(value)]="trademark.registrationNumber" [error]="validate('registrationNumber')"></app-input>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-territory-select label="{{'TRADEMARK.DESIGNATION_COUNTRY' | translate}}" [multiple]="false"
              [search]="true" initial="none" [labelOutside]="true" [error]="validate('countryOfDesignation')"
              [(value)]="trademark.countryOfDesignation"></app-territory-select>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-input label="{{'TRADEMARK.KIND' | translate}}" placeholder="{{'TRADEMARK.KIND' | translate}}"
              type="text" [(value)]="trademark.kind"></app-input>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-select initial="none" label="{{'TRADEMARK.TYPE' | translate}}" [options]="trademarkTypes"
              [multiple]="false" (change)="onTrademarkTypeChange($event)" [emitOnInit]="false" [value]="trademark.type"
              [labelOutside]="true" [error]="validate('type')"></app-select>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-datepicker label="{{'TRADEMARK.APPLICATION_DATE' | translate}}"
              placeholder="{{'TRADEMARK.APPLICATION_DATE' | translate}}"
              [(value)]="trademark.applicationDate"></app-datepicker>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-datepicker label="{{'TRADEMARK.PUBLICATION_DATE' | translate}}"
              placeholder="{{'TRADEMARK.PUBLICATION_DATE' | translate}}"
              [(value)]="trademark.publicationDate"></app-datepicker>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-datepicker label="{{'TRADEMARK.REGISTRATION_DATE' | translate}}"
              placeholder="{{'TRADEMARK.REGISTRATION_DATE' | translate}}"
              [(value)]="trademark.registrationDate"></app-datepicker>
          </div>
          <div class="fx-flex-50 p-8 border-box fx-column">
            <app-datepicker label="{{'TRADEMARK.EXPIRATION_DATE' | translate}}"
              placeholder="{{'TRADEMARK.EXPIRATION_DATE' | translate}}"
              [(value)]="trademark.expirationDate"></app-datepicker>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="fx-flex-30 pl-8 pb-8 border-box fx-column">
      <mat-card class="p-0">
        <div class="fx-row fx-wrap align-items-start justify-content-start">
          <div class="p-16 fx-flex-100">
            <span class="text bold">{{'TRADEMARK.REPRODUCTION_SHORT' | translate}}</span>
          </div>
          <mat-divider class=" fx-flex-100"></mat-divider>
          <div class="m-16 fx-row align-items-center justify-content-center fx-flex-100">
            <app-upload-picture [src]="trademark.reproductionURI" (pictureChange)="onReproductionChange($event)"
              class="fx-flex-100"></app-upload-picture>
          </div>
        </div>
      </mat-card>
      <mat-card class="p-0 fx-flex mt-16">
        <div class="fx-column h-100">
          <div class="p-16">
            <span class="text bold">{{'TRADEMARK.OFFICE' | translate}}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="p-16 fx-row align-items-center justify-content-center fx-flex-100">
            <app-property-office-select label="{{'ENTITIES.PROPERTY_OFFICE' | translate}}" class="fx-flex-100"
              [search]="true" (change)="onPropertyOfficeChange($event)" [(value)]="propertyOffice"
              [error]="validate('propertyOffice')"></app-property-office-select>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="fx-flex-100 pb-8 pt-8 border-box">
    <mat-card class="p-0">
      <div class="p-16 fx-row align-items-center justify-content-between">
        <span class="text bold">{{'TRADEMARK.CLASSIFICATION' | translate}}</span>
        <span class="small red ml-5"
          *ngIf="validate('niceClassification').value">{{'TRADEMARK.NICE_CLASSIFICATION_REQUIRED' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <div class="fx-row align-items-center justify-content-start mv-8"
          *ngFor="let niceClass of trademark.niceClassification">
          <app-nice-select label="{{'TRADEMARK_DECLARE.ADD_CLASS' | translate}}" [labelOutside]="false"
            [multiple]="false" [search]="true" class="mr-10 nice-selector" initial="none"
            [(value)]="niceClass.classNumber" [disabledValues]="disabledNiceClasses"
            [emitOnInit]="false"></app-nice-select>
          <app-input placeholder="{{'TRADEMARK_DECLARE.CLASS_DETAILS' | translate}}" type="text"
            [(value)]="niceClass.details" class="fx-flex"></app-input>
          <button mat-icon-button class="ml-10" (click)="removeClass(niceClass)">
            <mat-icon fontSet="material-icons-outlined" class="red">highlight_off</mat-icon>
          </button>
        </div>
        <mat-divider class="mv-8"
          *ngIf="trademark.niceClassification && trademark.niceClassification.length > 0"></mat-divider>
        <div class="fx-row align-items-center justify-content-start mt-8">
          <app-nice-select label="{{'TRADEMARK_DECLARE.ADD_CLASS' | translate}}" [labelOutside]="false"
            [multiple]="false" [search]="true" class="mr-10 nice-selector" [(value)]="newClass.classNumber"
            [disabledValues]="disabledNiceClasses" [emitOnInit]="false"></app-nice-select>
          <app-input placeholder="{{'TRADEMARK_DECLARE.CLASS_DETAILS' | translate}}" type="text"
            [(value)]="newClass.details" class="fx-flex"></app-input>
          <button class="main-button ml-10" (click)="addClass()" [disabled]="!newClass.classNumber"
            [class.disabled]="!newClass.classNumber">
            {{'TRADEMARK_DECLARE.ADD_CLASS' | translate}}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div *ngIf="trademark && mode === 'COMPARISON'">
  <mat-card class="p-0">
    <div class="p-16">
      <div class="fx-row align-items-center justify-content-start" *ngIf="differences.length > 0">
        <mat-icon fontSet="material-icons-outlined" class="main mr-10">info</mat-icon>
        <span class="text">{{'TRADEMARK.COMPARISON_DIFFERENCES' | translate}}</span>
      </div>
      <div class="fx-row align-items-center justify-content-start" *ngIf="differences.length === 0">
        <mat-icon fontSet="material-icons-outlined" class="green mr-10">check_circle</mat-icon>
        <span class="text">{{'TRADEMARK.COMPARISON_NO_DIFFERENCE' | translate}}</span>
      </div>
    </div>
    <mat-divider *ngIf="differences && differences.length > 0"></mat-divider>
    <div class="fx-row fx-wrap align-items-start justify-content-center p-16"
      *ngIf="differences && differences.length > 0">
      <div *ngFor="let property of differences; let last = last;" class="fx-flex-100 fx-row fx-wrap">
        <div class="fx-row align-items-start justify-content-between fx-flex-100"
          *ngIf="!['niceClassification','status','propertyOffice'].includes(property.property) && !property.property.toLowerCase().includes('date')">
          <div class="fx-flex-20 fx-column align-items-start justify-content-start">
            <span class="text bold">{{property.label | translate}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_OLD_VALUE' | translate}}</span>
            <span class="text">{{property.old || '-'}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_NEW_VALUE' | translate}}</span>
            <span class="text">{{property.new || '-'}}</span>
          </div>
        </div>
        <div class="fx-row align-items-start justify-content-between fx-flex-100"
          *ngIf="property.property.toLowerCase().includes('date')">
          <div class="fx-flex-20 fx-column align-items-start justify-content-start">
            <span class="text bold">{{property.label | translate}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_OLD_VALUE' | translate}}</span>
            <span class="text">{{formatDate(property.old)}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_NEW_VALUE' | translate}}</span>
            <span class="text">{{formatDate(property.new)}}</span>
          </div>
        </div>
        <div class="fx-row align-items-start justify-content-between fx-flex-100"
          *ngIf="property.property === 'status'">
          <div class="fx-flex-20 fx-column align-items-start justify-content-start">
            <span class="text bold">{{property.label | translate}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_OLD_VALUE' | translate}}</span>
            <span class="text" *ngIf="!property.old">{{property.old || '-'}}</span>
            <app-trademark-status *ngIf="property.old" [status]="property.old"></app-trademark-status>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_NEW_VALUE' | translate}}</span>
            <span class="text" *ngIf="!property.new">{{property.new || '-'}}</span>
            <app-trademark-status *ngIf="property.new" [status]="property.new"></app-trademark-status>
          </div>
        </div>
        <div class="fx-row align-items-start justify-content-between fx-flex-100"
          *ngIf="property.property === 'propertyOffice'">
          <div class="fx-flex-20 fx-column align-items-start justify-content-start">
            <span class="text bold">{{property.label | translate}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_OLD_VALUE' | translate}}</span>
            <span class="text">{{property.old ? property.old.name : '-'}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_NEW_VALUE' | translate}}</span>
            <span class="text">{{property.new ? property.new.name : '-'}}</span>
          </div>
        </div>
        <div class="fx-row align-items-start justify-content-between fx-flex-100"
          *ngIf="property.property === 'niceClassification'">
          <div class="fx-flex-20 fx-column align-items-start justify-content-start">
            <span class="text bold">{{property.label | translate}}</span>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_OLD_VALUE' | translate}}</span>
            <div class="fx-column align-items-start justify-content-start mb-10" *ngFor="let niceClass of property.old">
              <div class="chip main fx-row align-items-center justify-content-start">
                <span><b>{{'NICE_CLASSES.CLASS_NUMBER' |
                    translate}}{{niceClass.classNumber}}</b>&nbsp;-&nbsp;{{'NICE_CLASSES.'+niceClass.classNumber |
                  translate}}</span>
              </div>
              <span class="text">{{niceClass.details}}</span>
            </div>
          </div>
          <div class="fx-flex-35 fx-column align-items-start justify-content-start">
            <span class="small light">{{'TRADEMARK.COMPARISON_NEW_VALUE' | translate}}</span>
            <div class="fx-column align-items-start justify-content-start mb-10" *ngFor="let niceClass of property.new">
              <div class="chip main fx-row align-items-center justify-content-start">
                <span><b>{{'NICE_CLASSES.CLASS_NUMBER' |
                    translate}}{{niceClass.classNumber}}</b>&nbsp;-&nbsp;{{'NICE_CLASSES.'+niceClass.classNumber |
                  translate}}</span>
              </div>
              <span class="text">{{niceClass.details}}</span>
            </div>
          </div>
        </div>
        <mat-divider class="mv-16 fx-flex-100" *ngIf="!last"></mat-divider>
      </div>
    </div>
  </mat-card>
</div>