import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { EditTrademarkFamilyDialogComponent } from 'src/app/dialogs/edit-trademark-family-dialog/edit-trademark-family-dialog.component';
import { NiceClass } from 'src/app/models/niceClass';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { Trademark, TrademarkType } from 'src/app/models/trademark';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';
import { MessageService } from 'src/app/services/message.service';
import { PropertyOfficeService } from 'src/app/services/property-office.service';
import { TerritoryService } from 'src/app/services/territory.service';
import { TrademarkFamilyService } from 'src/app/services/trademark-family.service';
import { TrademarkService } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-trademark-content',
  templateUrl: './trademark-content.component.html',
  styleUrls: ['./trademark-content.component.scss']
})
export class TrademarkContentComponent implements OnInit, OnChanges {
  @Input() trademark: Trademark = {
    _id: undefined,
    identifierNumber: '',
    registrationNumber: undefined,
    applicationNumber: undefined,
    countryOfDesignation: '',
    kind: undefined,
    type: undefined,
    niceClassification: [],
    applicationDate: undefined,
    publicationDate: undefined,
    oppositionEndDate: undefined,
    registrationDate: undefined,
    expirationDate: undefined,
    reproductionURI: undefined,
    name: undefined,
    organizationId: undefined
  };
  @Input() refreshed?: Trademark;
  @Input() mode: "CONSULTATION" | "EDITION" | "COMPARISON" = "CONSULTATION";

  differences: { property: string, label: string, old: any, new: any, type: string }[] = [];

  public newClass: {
    classNumber?: number,
    details?: string
  } = {
      classNumber: undefined,
      details: undefined
    };

  disabledNiceClasses: number[] = []

  @Output() reproductionChange = new EventEmitter<any>();

  propertyOffice?: PropertyOffice;

  trademarkTypes: any[] = [
    {
      value: TrademarkType.SHAPE_3D,
      label: "TRADEMARK.TYPES.SHAPE_3D",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COLOUR,
      label: "TRADEMARK.TYPES.COLOUR",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COLOURS_COMBINATION,
      label: "TRADEMARK.TYPES.COLOURS_COMBINATION",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COMBINED,
      label: "TRADEMARK.TYPES.COMBINED",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.FIGURATIVE,
      label: "TRADEMARK.TYPES.FIGURATIVE",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.GENERAL,
      label: "TRADEMARK.TYPES.GENERAL",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.HOLOGRAM,
      label: "TRADEMARK.TYPES.HOLOGRAM",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.KENNFADEN,
      label: "TRADEMARK.TYPES.KENNFADEN",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.MOTION,
      label: "TRADEMARK.TYPES.MOTION",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.MULTIMEDIA,
      label: "TRADEMARK.TYPES.MULTIMEDIA",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.NUMBER,
      label: "TRADEMARK.TYPES.NUMBER",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.OLFACTORY,
      label: "TRADEMARK.TYPES.OLFACTORY",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.OTHER,
      label: "TRADEMARK.TYPES.OTHER",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.PACKING,
      label: "TRADEMARK.TYPES.PACKING",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.PATTERN,
      label: "TRADEMARK.TYPES.PATTERN",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.POSITION,
      label: "TRADEMARK.TYPES.POSITION",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.SOUND,
      label: "TRADEMARK.TYPES.SOUND",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.STYLIZED_CHARACTERS,
      label: "TRADEMARK.TYPES.STYLIZED_CHARACTERS",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.UNDEFINED,
      label: "TRADEMARK.TYPES.UNDEFINED",
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.WORD,
      label: "TRADEMARK.TYPES.WORD",
      selected: false,
      disabled: false
    }
  ]

  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private propertyOfficeService: PropertyOfficeService,
    private router: Router,
    private toastr: ToastrService,
    private territoryService: TerritoryService,
    private trademarkService: TrademarkService,
    private trademarkFamilyService: TrademarkFamilyService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.getPropertyOffice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["mode"] && changes["mode"].currentValue === "COMPARISON") {
      this.computeDifferences();
    }
    if (this.trademark && this.trademark.niceClassification) {
      this.disabledNiceClasses = this.trademark.niceClassification.map((niceClass) => Number(niceClass.classNumber));
    }
  }

  getFamily() {
    if (this.trademark && this.trademark.family && this.trademark.family.ref) {
      return {
        exist: true,
        label: (this.trademark.family.ref as TrademarkFamily).label
      }
    } else {
      return {
        exist: false
      }
    }
  }

  toFamily() {
    if (this.trademark && this.trademark.family && this.trademark.family.ref) {
      this.router.navigateByUrl(`/trademarks/families/${(this.trademark.family.ref as TrademarkFamily)._id}`)
    }
  }

  async actionFamily() {
    if (this.trademark) {
      const config: MatDialogConfig = {
        panelClass: 'dialog-container',
        width: '480px',
        data: {
          trademark: this.trademark,
          family: this.trademark.family ? (this.trademark.family.ref as TrademarkFamily) : undefined
        }
      }
      const dialog = this.dialog.open(EditTrademarkFamilyDialogComponent, config);
      const result: { mode: "choose" | "new", newFamily?: Partial<TrademarkFamily>, family?: TrademarkFamily } = await firstValueFrom(dialog.afterClosed());
      try {
        if (result && result.mode === "choose" && result.family && result.family._id) {
          await this.trademarkService.updateFamily(this.trademark, result.family._id);
          this.trademark.family = {
            manual: true,
            ref: result.family
          };
          this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.TRADEMARK_FAMILY_UPDATED', { name: this.trademark.name || this.trademark.identifierNumber }));
        } else if (result && result.mode === "new" && result.newFamily) {
          const toCreate = {
            ...result.newFamily,
            trademarks: [this.trademark._id]
          }
          const created = await this.trademarkFamilyService.create(toCreate);
          this.trademark.family = {
            manual: true,
            ref: created
          };
          this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.FAMILY_CREATED', { name: created.label }));
          if (created && created._id) {
            await this.trademarkService.updateFamily(this.trademark, created._id);
            this.toastr.success(this.translate.instant('TRADEMARK_FAMILY.TRADEMARK_FAMILY_UPDATED', { name: this.trademark.name || this.trademark.identifierNumber }));
          }
        }

      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }


  async getPropertyOffice() {
    if (this.trademark && this.trademark.propertyOffice)
      this.propertyOffice = await this.propertyOfficeService.retrieveOne(this.trademark.propertyOffice?.ref);
  }

  validate(field: string) {
    if (["registrationNumber", "applicationNumber"].includes(field) && (!this.trademark?.registrationNumber && !this.trademark?.applicationNumber)) {
      return {
        value: true,
        message: this.translate.instant('TRADEMARK.IDENTIFIER_NUMBER_REQUIRED')
      }
    } else if (field === "countryOfDesignation" && !this.trademark?.countryOfDesignation) {
      return {
        value: true,
        message: this.translate.instant('TRADEMARK.DESIGNATION_COUNTRY_REQUIRED')
      }
    } else if (field === "niceClassification" && (!this.trademark?.niceClassification || this.trademark.niceClassification.length === 0)) {
      return {
        value: true,
        message: this.translate.instant('TRADEMARK.NICE_CLASSIFICATION_REQUIRED')
      }
    } else if (field === "propertyOffice" && !this.trademark?.propertyOffice) {
      return {
        value: true,
        message: this.translate.instant('TRADEMARK.PROPERTY_OFFICE_REQUIRED')
      }
    } else if (field === "type" && !this.trademark?.type) {
      return {
        value: true,
        message: this.translate.instant('TRADEMARK.TYPE_REQUIRED')
      }
    } else {
      return {
        value: false,
        message: undefined
      }
    }
  }

  trademarkNiceClassNumbers(): number[] {
    return this.trademark?.niceClassification?.map(niceClass => Number(niceClass.classNumber)) || [];
  }


  getCountry() {
    if (this.trademark?.countryOfDesignation) {
      return this.translate.instant(`TERRITORIES.${this.territoryService.retrieveOne(this.trademark?.countryOfDesignation)?.id.toUpperCase()}`);
    } else {
      return '-';
    }
  }

  addClass() {
    if (this.trademark && this.newClass.classNumber) {
      this.trademark.niceClassification?.push({
        classNumber: this.newClass.classNumber,
        details: this.newClass.details
      });
      this.disabledNiceClasses.push(this.newClass.classNumber);
      this.newClass = {
        classNumber: undefined,
        details: undefined
      }
    }
  }

  removeClass(niceClass: { classNumber: number, details?: string }) {
    if (this.trademark) {
      this.trademark.niceClassification?.splice(this.trademark.niceClassification.indexOf(niceClass), 1);
    }
  }

  formatDate(date?: Moment) {
    return date ? moment(date).format('LL') : '-';
  }

  _labelForProperty(property: string) {
    const labels: { [prop: string]: string } = {
      identifierNumber: 'TRADEMARK.IDENTIFIER_NUMBER',
      registrationNumber: 'TRADEMARK.REGISTRATION_NUMBER',
      applicationNumber: 'TRADEMARK.APPLICATION_NUMBER',
      countryOfDesignation: 'TRADEMARK.DESIGNATION_COUNTRY',
      kind: 'TRADEMARK.KIND',
      type: 'TRADEMARK.TYPE',
      niceClassification: 'TRADEMARK.NICE_CLASSIFICATION',
      applicationDate: 'TRADEMARK.APPLICATION_DATE',
      publicationDate: 'TRADEMARK.PUBLICATION_DATE',
      oppositionEndDate: 'TRADEMARK.OPPOSITION_END_DATE',
      registrationDate: 'TRADEMARK.REGISTRATION_DATE',
      expirationDate: 'TRADEMARK.EXPIRATION_DATE',
      reproductionURI: 'TRADEMARK.REPRODUCTION',
      name: 'TRADEMARK.NAME',
      status: 'TRADEMARK.STATUS',
      propertyOffice: 'ENTITIES.PROPERTY_OFFICE'
    };
    return labels[property as keyof { [prop: string]: string }];
  }

  async _getReproductionAsAarrayBuffer(uri: string) {
    const blob = await this.trademarkService.getReproductionAsBlob(uri);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      return fileReader.result;
    }
    fileReader.readAsArrayBuffer(blob);
  }

  // TODO: compare image arraybuffer as well
  async computeDifferences() {
    const territories = await this.territoryService.retrieve();
    this.differences = [];
    for (const prop in this.refreshed) {
      if (!["__v", "_id", "organizationId", "createdAt", "updatedAt"].includes(prop) && this.refreshed) {
        if (!["niceClassification", "countryOfDesignation", "reproductionURI", "propertyOffice"].includes(prop) && this.trademark[prop as keyof Trademark] != this.refreshed[prop as keyof Trademark]) {
          this.differences.push({
            property: prop,
            old: this.trademark[prop as keyof Trademark],
            new: this.refreshed[prop as keyof Trademark],
            type: typeof this.trademark[prop as keyof Trademark],
            label: this._labelForProperty(prop)
          });
        } else if (prop === "niceClassification") {
          const oldOne = this.trademark.niceClassification?.sort((a, b) => a.classNumber - b.classNumber);
          const newOne = this.refreshed.niceClassification?.sort((a, b) => a.classNumber - b.classNumber)
          if (oldOne && newOne && oldOne.some((niceClass, index) => oldOne[index].classNumber !== newOne[index].classNumber || oldOne[index].details !== newOne[index].details)) {
            this.differences.push({
              property: prop,
              old: this.trademark[prop as keyof Trademark],
              new: this.refreshed[prop as keyof Trademark],
              type: typeof this.trademark[prop as keyof Trademark],
              label: this._labelForProperty(prop)
            });
          }
        } else if (prop === "countryOfDesignation") {
          const oldOne = territories.find((territory) => (territory.id === this.trademark[prop as keyof Trademark] || (territory.alternateCodes && territory.alternateCodes.includes(this.trademark[prop as keyof Trademark] as string))));
          const newOne = territories.find((territory) => this.refreshed && (territory.id === this.refreshed[prop as keyof Trademark] || (territory.alternateCodes && territory.alternateCodes.includes(this.refreshed[prop as keyof Trademark] as string))));
          if (oldOne && newOne && oldOne.id !== newOne.id) {
            this.differences.push({
              property: prop,
              old: this.trademark[prop as keyof Trademark],
              new: this.refreshed[prop as keyof Trademark],
              type: typeof this.trademark[prop as keyof Trademark],
              label: this._labelForProperty(prop)
            });
          }
        } else if (prop === "propertyOffice") {
          const oldOne = this.trademark.propertyOffice;
          const newOne = this.refreshed.propertyOffice;
          if (oldOne && newOne && oldOne.ref !== newOne.ref) {
            this.differences.push({
              property: prop,
              old: this.trademark[prop as keyof Trademark],
              new: this.refreshed[prop as keyof Trademark],
              type: typeof this.trademark[prop as keyof Trademark],
              label: this._labelForProperty(prop)
            });
          }
        }
        // else if (prop === "reproductionURI") {
        //   const oldOne = await this._getReproductionAsAarrayBuffer(this.trademark.reproductionURI ?? '');
        //   const newOne = await this._getReproductionAsAarrayBuffer(this.refreshed.reproductionURI ?? '');
        //   console.log(oldOne)
        //   console.log(newOne)
        // }
      }
    }
    console.log(this.differences)
  }

  onReproductionChange(reproduction?: File) {
    this.reproductionChange.emit(reproduction);
  }

  onPropertyOfficeChange(event: any) {
    if (event && event.value) {
      this.propertyOffice = event.value;
      this.trademark.propertyOffice = {
        ref: event.value._id,
        code: event.value.code,
        name: event.value.name,
        logo: event.value.logo
      }
    }
  }

  onTrademarkTypeChange(event: any) {
    if (event && event.value && event.value.value) {
      this.trademark.type = event.value.value;
    }
  }

}
