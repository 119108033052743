import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from "lodash";
import { Document, DocumentFilters, DocumentType } from '../models/document';
import { User } from '../models/user';
import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, firstValueFrom, last, lastValueFrom, map, of, tap } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadDocumentsDialogComponent } from '../dialogs/upload-documents-dialog/upload-documents-dialog.component';
import { UploadFile, UploadFileStatus } from '../models/uploadFile';
import { MessageService } from './message.service';
import { Batch } from '../models/batch';
import { BatchService } from './batch.service';
import { Paginator } from '../models/paginator';
import { AuthenticationService } from './authentication.service';
import { DocumentEvidence } from '../models/documentEvidence';
import { DocumentMetadata, DocumentMetadataAndEvidence } from '../models/documentMetadata';
import { Case } from '../models/case';
import { CaseService } from './case.service';
import { Trademark } from '../models/trademark';
import { TranslateService } from '@ngx-translate/core';
import { TrademarkFamily } from '../models/trademarkFamily';

export interface Data {
  _id: any;
  count: number;
}


export interface DocumentAggregatedData {
  started: boolean,
  data: Data[],
  loading: boolean,
  error: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public classes: { value: string, label: string, selected: boolean }[] = [];

  users: User[] = [];


  private documents: Document[] = [];

  uploadDocuments: UploadFile[] = [];
  batch?: Batch;

  public pager: Paginator<Document> = {
    page: 1,
    limit: 10,
    offset: 0,
    sort: '-createdAt',
    docs: [],
    totalDocs: 0
  }

  public filters: DocumentFilters = {
    query: undefined,
    classNumber: [],
    territories: [],
    documentClass: [],
    trademark: [],
    trademarkFamily: [],
    period: {
      start: undefined,
      end: undefined
    }
  }

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private batchService: BatchService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private http: HttpClient,
    private translate: TranslateService
  ) {
  }

  resetDocumentsSearch() {
    this.pager = {
      page: 1,
      limit: 10,
      offset: 0,
      sort: '-createdAt',
      docs: [],
      totalDocs: 0
    }

    this.filters = {
      query: undefined,
      classNumber: [],
      territories: [],
      documentClass: [],
      trademark: [],
      trademarkFamily: [],
      nonUse: undefined,
      period: {
        start: undefined,
        end: undefined
      }
    }
  }

  graph(value: number, period: string): Promise<Data[]> {
    let params = new HttpParams()
      .set('value', value)
      .set('period', period)

    return firstValueFrom(this.http
      .get<Data[]>(`${environment.backend}/documents/graph`, { params }));
  }

  countAll(territories?: string[], classNumbers?: number[], trademarks?: Trademark[], trademarkFamilies?: TrademarkFamily[], classes?: string[], period?: { start?: number, end?: number }, query?: string): Promise<{ count: number }> {
    let params = new HttpParams();

    if (territories) {
      territories.forEach(territory => {
        params = params.append('countryOfDesignation[]', territory.toUpperCase());
      })
    }

    if (classNumbers) {
      classNumbers.forEach(classNumber => {
        params = params.append('classNumber[]', classNumber);
      })
    }

    if (classes) {
      classes.forEach((documentClass) => {
        params = params.append('documentClass[]', documentClass);
      })
    }

    if (trademarks) {
      trademarks.forEach((trademark) => {
        if (trademark._id) {
          params = params.append('trademark[]', trademark._id);
        }
      })
    }

    if (trademarkFamilies) {
      trademarkFamilies.forEach((family) => {
        if (family._id) {
          params = params.append('trademarkFamily[]', family._id);
        }
      })
    }

    if (period && period.start) {
      params = params.append('start', `${period.start}`);
    }

    if (period && period.end) {
      params = params.append('end', `${period.end}`);
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<{ count: number }>(`${environment.backend}/documents/count`, { params }));
  }

  retrieveAll(pageNumber: number, limit: number, offset: number, sort?: string, territories?: string[], classNumbers?: number[], trademarks?: Trademark[], trademarkFamilies?: TrademarkFamily[], classes?: string[], period?: { start?: number, end?: number }, nonUse?: boolean, query?: string): Promise<Paginator<Document>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    if (territories) {
      territories.forEach(territory => {
        params = params.append('countryOfDesignation[]', territory.toUpperCase());
      })
    }

    if (classNumbers) {
      classNumbers.forEach(classNumber => {
        params = params.append('classNumber[]', classNumber);
      })
    }

    if (classes) {
      classes.forEach((documentClass) => {
        params = params.append('documentClass[]', documentClass);
      })
    }

    if (trademarks) {
      trademarks.forEach((trademark) => {
        if (trademark._id) {
          params = params.append('trademark[]', trademark._id);
        }
      })
    }

    if (trademarkFamilies) {
      trademarkFamilies.forEach((family) => {
        if (family._id) {
          params = params.append('trademarkFamily[]', family._id);
        }
      })
    }

    if (period && period.start) {
      params = params.append('start', `${period.start}`);
    }

    if (period && period.end) {
      params = params.append('end', `${period.end}`);
    }

    if (nonUse !== undefined) {
      params = params.set('nonUse', nonUse);
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Document>>(`${environment.backend}/documents/search`, { params }));
  }

  aggregate(facet: string, countriesOfDesignation?: string[], classNumbers?: number[], trademarks?: Trademark[], trademarkFamilies?: TrademarkFamily[], classes?: string[], period?: { start?: number, end?: number }, query?: string): Promise<Data[]> {
    let params = new HttpParams();

    if (countriesOfDesignation) {
      countriesOfDesignation.forEach(country => {
        params = params.append('countryOfDesignation[]', country.toUpperCase());
      })
    }

    if (classNumbers) {
      classNumbers.forEach(classNumber => {
        params = params.append('classNumber[]', classNumber);
      })
    }

    if (classes) {
      classes.forEach((documentClass) => {
        params = params.append('documentClass[]', documentClass);
      })
    }

    if (trademarks) {
      trademarks.forEach((trademark) => {
        if (trademark._id) {
          params = params.append('trademark[]', trademark._id);
        }
      })
    }

    if (trademarkFamilies) {
      trademarkFamilies.forEach((family) => {
        if (family._id) {
          params = params.append('trademarkFamily[]', family._id);
        }
      })
    }

    if (period && period.start) {
      params = params.append('start', `${period.start}`);
    }

    if (period && period.end) {
      params = params.append('end', `${period.end}`);
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Data[]>(`${environment.backend}/documents/aggregate/${facet}`, { params }));
  }

  hierarchize(pageNumber: number, limit: number, offset: number, sort: string, level: string, filters: { [type: string]: any }, query?: string): Promise<{ data: Data[], count: number }> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)
      .set('sort', sort)

    Object.keys(filters).forEach(type => {
      switch (type) {
        case 'trademark':
          params = params.append('trademark', `${filters[type].identifierNumber}:${filters[type].countryOfDesignation}`);
          break;
        case 'territory':
          params = params.append('territory', filters[type]);
          break;
        case 'language':
          params = params.append('lang', filters[type]);
          break;
        case 'nice':
          params = params.append('classNumber', filters[type]);
          break;
        case 'year':
          params = params.append('year', filters[type]);
          break;
        case 'class':
          params = params.append('documentClass', filters[type]);
          break;
        default:
          break;
      }
    })

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<{ data: Data[], count: number }>(`${environment.backend}/documents/hierarchize/${level}`, { params }));
  }

  retrieve(_id: string) {
    return firstValueFrom(this.http
      .get<Document>(`${environment.backend}/documents/${_id}`));
  }

  retrieveEvidences(_id: string) {
    return firstValueFrom(this.http
      .get<DocumentEvidence[]>(`${environment.backend}/documents/${_id}/evidences`));
  }

  retrieveMetadata(_id: string) {
    return firstValueFrom(this.http
      .get<DocumentMetadata[]>(`${environment.backend}/documents/${_id}/metadata`));
  }

  addMetadata(_id: string, metadataAndEvidences: DocumentMetadataAndEvidence[]) {
    return firstValueFrom(this.http
      .post<Document>(`${environment.backend}/documents/${_id}/metadata`, metadataAndEvidences));
  }

  updateMetadata(_id: string, metadataAndEvidence: DocumentMetadataAndEvidence) {
    return firstValueFrom(this.http
      .put<Document>(`${environment.backend}/documents/${_id}/metadata/${metadataAndEvidence._id}`, metadataAndEvidence));
  }

  deleteMetadata(_id: string, metadataId: string) {
    return firstValueFrom(this.http
      .delete<Document>(`${environment.backend}/documents/${_id}/metadata/${metadataId}`));
  }

  analyze(_id: string) {
    return firstValueFrom(this.http
      .put<void>(`${environment.backend}/documents/${_id}/analyze`, {}));
  }

  delete(_id: string) {
    return firstValueFrom(this.http
      .delete<any>(`${environment.backend}/documents/${_id}`));
  }

  deleteMany(ids: string[]) {
    return firstValueFrom(this.http
      .delete<any>(`${environment.backend}/documents`, { body: { documents: ids } }));
  }

  update(document: Document) {
    const body = {
      name: document.name,
      type: document.type,
      class: document.class,
      confidential: document.confidential,
      trademarks: document.trademarks,
      territories: document.territories,
      niceClassification: document.niceClassification,
      langs: document.langs,
      period: document.period
    }
    return firstValueFrom(this.http
      .put<Document>(`${environment.backend}/documents/${document._id}`, body));
  }


  icon(document: any) {
    switch (document.type) {
      case 'application/pdf':
        return '/assets/icons/pdf.svg';
      case 'image/jpg':
      case 'image/jpeg':
        return '/assets/icons/jpg.svg';
      case 'image/png':
        return '/assets/icons/png.svg';
      case 'image/tiff':
        return '/assets/icons/tiff.svg';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '/assets/icons/xls.svg';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '/assets/icons/doc.svg';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return '/assets/icons/ppt.svg';
      case 'text/csv':
        return '/assets/icons/csv.svg';
      case 'text/html':
        return '/assets/icons/html.svg';
      case 'application/zip':
        return '/assets/icons/zip.svg';
      default:
        return '/assets/icons/file.svg';
    }
  }

  extension(type?: string) {
    switch (type) {
      case 'application/pdf':
        return 'pdf';
      case 'image/jpg':
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'image/tiff':
        return 'tiff';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/msword':
        return 'doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.ms-powerpoint':
        return 'ppt';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      case 'text/csv':
        return 'csv';
      case 'text/html':
        return 'html';
      case 'application/zip':
        return 'zip';
      default:
        return undefined;
    }
  }

  size(document: any) {
    if (document.size >= 1024 * 1024) {
      return `${(document.size / (1024 * 1024)).toFixed(1)} Mo`
    } else if (document.size >= 1024) {
      return `${Math.round(document.size / 1024)} Ko`
    } else {
      return `${document.size} o`
    }
  }

  period(document: Document) {
    moment.locale(this.translate.currentLang)
    if (document.period) {
      const start = moment(document.period.start);
      const end = moment(document.period.end);

      if (start.year() === end.year()) {
        if (start.month() === end.month()) {
          return `${start.format('MMM')} ${start.format('YY')}`

        } else {
          return `${start.format('MMM')} - ${end.format('MMM')} ${start.format('YY')}`
        }
      } else {
        return `${start.format('MMM')} ${start.format('YY')} - ${end.format('MMM')} ${end.format('YY')}`
      }
    }
    return '-';
  }

  async detectDuplicates(files: UploadFile[]): Promise<Partial<Document>[]> {
    const documents = files.map(f => ({
      name: f.name,
      size: f.size,
      type: f.type
    }));
    return firstValueFrom(this.http
      .post<Partial<Document>[]>(`${environment.backend}/documents/duplicates`, documents));
  }



  async openUploadDialog(files: File[], caseObject?: Case) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      autoFocus: false,
      data: {
        files,
        case: caseObject
      }
    }
    const dialog = this.dialog.open(UploadDocumentsDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
  }

  async download(document: Document, sca: boolean = false) {
    const token = this.authService.getStrongAccessToken();
    return firstValueFrom(this.http.get(`${environment.backend}/documents/${document._id}/download`, {
      headers: sca ? {
        Authorization: `Bearer ${token}`,
        sca: 'true'
      } : {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    }))
  }

  async upload(batch: Batch, documents: UploadFile[], caseObject?: Case) {
    this.uploadDocuments = documents;
    this.batch = batch;
    this._message.emitChange("UPLOAD", "SHOW");
    // Extending access token
    const isAboutToExpire = this.authService.isAccessTokenAboutToExpire();
    if (isAboutToExpire) {
      await firstValueFrom(this.authService.refreshToken());
    }
    await Promise.all(this.uploadDocuments.map(async (file: UploadFile) => {
      file.batch = {
        ref: batch._id!,
        number: batch.number
      };
      file.status = UploadFileStatus.UPLOADING;
      file.progress = 0;
      const formData = new FormData();
      formData.append("document", file);
      formData.append("name", file.filename || "");
      formData.append("type", file.type);
      formData.append("size", file.size.toString());
      formData.append("batchRef", file.batch.ref);
      formData.append("batchNumber", file.batch.number.toString());
      formData.append("documentClass", file.class);
      formData.append("confidential", JSON.stringify(file.confidential));
      formData.append("analysis", file.analysis);
      formData.append("nonUse", JSON.stringify(file.nonUse));
      if (file.territories && file.territories.length > 0) file.territories.forEach(t => formData.append('territories[]', t));
      if (file.niceClassification && file.niceClassification.length > 0) file.niceClassification.forEach(n => formData.append('niceClassification[]', n.toString()));
      if (file.langs && file.langs.length > 0) file.langs.forEach(l => formData.append('langs[]', l));
      if (file.period && file.period.start && file.period.end) {
        formData.append("periodStart", file.period.start.format());
        formData.append("periodEnd", file.period.end.format());
      }

      const res = await lastValueFrom(this.http.post<Document>(`${environment.backend}/documents`, formData, { reportProgress: true, observe: 'events' }).pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              file.progress = Math.round(event.loaded * 100 / event.total);
            }
          }
          if (event.type === HttpEventType.Response) {
            if (event.ok) {
              file.progress = 100;
              file.status = UploadFileStatus.DONE;
              file.documentId = event.body?._id;
            } else {
              file.status = UploadFileStatus.ERROR;
            }
          }
          return of(event);
        }),
        catchError(err => {
          file.status = UploadFileStatus.ERROR;
          return of(err)
        })));
    }));
    await this.batchService.close(batch._id!);
    if (caseObject && caseObject._id) {
      await this.caseService.link(caseObject._id, this.uploadDocuments.filter(doc => doc.documentId).map((doc) => doc.documentId as string))
      this._message.emitChange("CASE", "REFRESH", { caseId: caseObject._id });
    }

  }

  flushAndClosePanel() {
    this._message.emitChange("UPLOAD", "HIDE");
    this.uploadDocuments = [];
    this.batch = undefined;
  }

}
