<div class="p-20 fx-row fx-wrap align-items-center justify-content-start ">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'TRADEMARK_FAMILY.LINK_TRADEMARKS' | translate}}</span>
  </div>
  <div class="text mv-20" [innerHTML]="'TRADEMARK_FAMILY.LINK_TRADEMARKS_HINT' | translate:family"></div>
  <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
    class="fx-flex-100" [(value)]="trademarks" [emitOnInit]="true"
    [disabledValues]="disabledValues"></app-trademark-select>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onLink()"
      [disabled]="isDisabled()">{{'TRADEMARK_FAMILY.LINK_TRADEMARKS'
      | translate}}</button>
  </div>
</div>