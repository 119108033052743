<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'TRADEMARKS_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'TRADEMARKS_LIST.SUBTITLE' | translate}}</span>
  </div>
  <div class="fx-row">
    <button class="main-button ml-20" routerLink="new"
      *ngIf="!loading && done && !noDocs && displayMode === 'trademarks'" aclBasedElement resource="TRADEMARK"
      [scopes]="['C']">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_circle_outline</mat-icon>
        {{'TRADEMARKS_LIST.NEW' | translate}}
      </div>
    </button>
    <button class="main-button ml-20" (click)="newFamily()"
      *ngIf="!loading && done && !noDocs && displayMode === 'families'">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_circle_outline</mat-icon>
        {{'TRADEMARKS_LIST.NEW_FAMILY' | translate}}
      </div>
    </button>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card *ngIf="!loading && done && !noDocs">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label class="text">
        {{'ENTITIES.TRADEMARKS' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <div class="fx-row align-items-center justify-content-between mt-16 mb-16">
          <div class="fx-row align-items-center justify-content-start">
            <app-trademark-family-select label="{{'ENTITIES.TRADEMARK_FAMILIES' | translate}}" [multiple]="true"
              [search]="true" style="width:210px;" (change)="onTrademarkFamilyChange($event)"
              [emitOnInit]="false"></app-trademark-family-select>
            <app-territory-select label="{{'ENTITIES.COUNTRY_OF_DESIGNATION' | translate}}" [multiple]="true"
              [search]="true" initial="none" (change)="onCountryOfDesignationChange($event)" [emitOnInit]="false"
              style="width:210px;" class="ml-10"></app-territory-select>
            <app-nice-select label="{{'TRADEMARKS_LIST.FILTERS.PRODUCTS_SERVICES' | translate}}" [multiple]="true"
              [search]="true" class="ml-10" initial="none" (change)="onNiceClassificationChange($event)"
              [emitOnInit]="false" style="width:210px;"></app-nice-select>
            <div class="fx-row fw-wrap align-items-baseline justify-content-start ml-10">
              <app-animated-number [value]="trademarks.totalDocs" [class]="'bold main'"></app-animated-number>
              &nbsp;
              <span class="text"><span class="bold main">{{((trademarks.totalDocs > 1 ? 'ENTITIES.TRADEMARKS' :
                  'ENTITIES.TRADEMARK') |
                  translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
            </div>
          </div>
          <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search"
            [(value)]="filters.query" (change)="onQueryChange($event)"></app-input>
        </div>
        <app-trademarks-table [dataSource]="dataSource" [trademarks]="trademarks" (sort)="onSortChange($event)"
          (page)="handlePage($event)" *ngIf="!loading && done && !noDocs"></app-trademarks-table>
        <app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
          [title]="'TRADEMARKS_LIST.NO_TRADEMARKS'" [message]="'TRADEMARKS_LIST.NO_TRADEMARKS_MESSAGE'"
          [button]="noTrademarkButton"></app-empty-placeholder>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="text">
        {{'ENTITIES.TRADEMARK_FAMILIES' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <div class="fx-row align-items-center justify-content-between mt-16 mb-16">
          <div class="fx-row align-items-center justify-content-start">
            <div class="fx-row fw-wrap align-items-baseline justify-content-start">
              <app-animated-number [value]="trademarkFamilies.totalDocs" [class]="'bold main'"></app-animated-number>
              &nbsp;
              <span class="text"><span class="bold main">{{((trademarkFamilies.totalDocs > 1 ?
                  'ENTITIES.TRADEMARK_FAMILIES' :
                  'ENTITIES.TRADEMARK_FAMILY') |
                  translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
            </div>
          </div>
          <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search"
            [(value)]="filtersFamily.query" (change)="onQueryFamilyChange($event)"></app-input>
        </div>
        <app-trademark-families-table [dataSource]="dataSourceFamilies" [families]="trademarkFamilies"
          (sort)="onSortFamilyChange($event)" (page)="handleFamilyPage($event)"
          *ngIf="!loadingFamilies && doneFamilies && !noDocsFamilies"></app-trademark-families-table>
        <app-empty-placeholder *ngIf="!loadingFamilies && doneFamilies && noDocsFamilies" class="fx-flex" [offset]="125"
          [title]="'TRADEMARKS_LIST.NO_TRADEMARK_FAMILIES'" [message]="'TRADEMARKS_LIST.NO_TRADEMARK_FAMILIES_MESSAGE'"
          [button]="noTrademarkFamiliesButton"></app-empty-placeholder>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>