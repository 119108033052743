import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Data, DocumentAggregatedData, DocumentService } from 'src/app/services/document.service';
import * as am4core from "@amcharts/amcharts4/core";
import { DocumentFilters } from 'src/app/models/document';
import * as _ from 'lodash';


@Component({
  selector: 'app-documents-report',
  templateUrl: './documents-report.component.html',
  styleUrls: ['./documents-report.component.scss']
})
export class DocumentsReportComponent implements OnInit {

  @Input() filters: DocumentFilters = {
    query: undefined,
    classNumber: [],
    territories: [],
    documentClass: [],
    trademark: [],
    trademarkFamily: [],
    nonUse: undefined,
    period: {
      start: undefined,
      end: undefined
    }
  }

  count: number = 0;

  documentClasses: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTypes: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTerritories: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentPeriods: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTrademarks: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentNice: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };


  constructor(
    private documentService: DocumentService,
    private toastr: ToastrService
  ) {
    // am4core.options.queue = true;
  }

  ngOnInit(): void {
    this.aggregateDocuments();
  }

  async aggregateDocuments() {
    await this.countDocuments();
    await this.aggregateDocumentsClasses();
    await this.aggregateDocumentsTypes();
    await this.aggregateDocumentsTrademarks();
    await this.aggregateDocumentsNice();
    await this.aggregateDocumentsTerritories();
    await this.aggregateDocumentsPeriods();
  }

  async countDocuments() {
    try {
      this.count = (await this.documentService.countAll(this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query)).count;
    } catch (err) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsClasses() {
    try {
      this.documentClasses.started = true;
      this.documentClasses.loading = true;
      this.documentClasses.data = await this.documentService.aggregate('class', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentClasses.loading = false;
    } catch (err) {
      this.documentClasses.loading = false;
      this.documentClasses.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTypes() {
    try {
      this.documentTypes.started = true;
      this.documentTypes.loading = true;
      this.documentTypes.data = await this.documentService.aggregate('type', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentTypes.loading = false;
    } catch (err) {
      this.documentTypes.loading = false;
      this.documentTypes.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTerritories() {
    try {
      this.documentTerritories.started = true;
      this.documentTerritories.loading = true;
      this.documentTerritories.data = await this.documentService.aggregate('territory', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentTerritories.loading = false;
    } catch (err) {
      this.documentTerritories.loading = false;
      this.documentTerritories.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsPeriods() {
    try {
      this.documentPeriods.started = true;
      this.documentPeriods.loading = true;
      this.documentPeriods.data = await this.documentService.aggregate('period', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentPeriods.loading = false;
    } catch (err) {
      this.documentPeriods.loading = false;
      this.documentPeriods.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTrademarks() {
    try {
      this.documentTrademarks.started = true;
      this.documentTrademarks.loading = true;
      this.documentTrademarks.data = await this.documentService.aggregate('trademark', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentTrademarks.loading = false;
    } catch (err) {
      this.documentTrademarks.loading = false;
      this.documentTrademarks.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsNice() {
    try {
      this.documentNice.started = true;
      this.documentNice.loading = true;
      this.documentNice.data = await this.documentService.aggregate('nice', this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.trademarkFamily, this.filters.documentClass, this.filters.period, this.filters.query);
      this.documentNice.loading = false;
    } catch (err) {
      this.documentNice.loading = false;
      this.documentNice.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }
}
